import "babel-polyfill";

import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";
import VueAnalytics from "vue-analytics";
import * as VueGoogleMaps from "vue2-google-maps";
import Home from "./views/Home.vue";
import Contact from "./views/contact/Index.vue";
import Confirm from "./views/contact/Confirm.vue";
import ThankYou from "./views/contact/ThankYou.vue";
import Buy from "./views/Buy.vue";
import Use from "./views/Use.vue";
import Price from "./views/Price.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import Voice from "./views/Voice.vue";
import NotFound from "./views/404.vue";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

import Es6Promise from "es6-promise";
Es6Promise.polyfill();

Vue.use(VueHead);
Vue.use(Router);

const apiKey = "AIzaSyA3KASo9dVdH7ZiIzcryCkYOGNhuuoQiNw";
Vue.use(VueGoogleMaps, {
  load: {
    key: apiKey,
    libraries: "places",
  },
});

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/buy",
      name: "buy",
      component: Buy,
    },
    {
      path: "/use",
      name: "use",
      component: Use,
    },
    {
      path: "/price",
      name: "price",
      component: Price,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
    {
      path: "/contact/confirm",
      name: "contact/confirm",
      component: Confirm,
    },
    {
      path: "/contact/thank-you",
      name: "contact/thank-you",
      component: ThankYou,
    },
    {
      path: "/voice",
      name: "voice",
      component: Voice,
    },
    {
      path: "/404",
      name: "404",
      component: NotFound,
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

Vue.use(VueAnalytics, {
  id: "UA-134372746-2",
  router: router,
});
