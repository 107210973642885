<template>
  <div class="use">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-sub-title">コーヒー焙煎機を</div>
        <div class="content-header-title">使える</div>
      </div>
    </div>
    <div class="common-main">
      <div class="common-title-group">
        <div class="text-headline-ja common-title">
          好きな時間に自由に
          <br/>
          コーヒー自家焙煎を追求できる
        </div>
        <div class="text-headline-ja common-subtitle">
          ディスカバリーをはじめ、工房にある3種類もの焙煎機を、事前予約でお好きな時間に自由にご利用いただけます。
        </div>
      </div>
      <div class="cross-wrapper">
        <div class="cross-img-group cross-img-group-1"></div>
        <div class="cross-text-group">
          <div class="cross-text-title">
            初期投資いらず。すぐに焙煎ができる場をつくりました。
          </div>
          <div class="cross-text-message">
            「自分で焙煎したいけど、高そうだし難しそう…」「タイプが色々あって、どれを購入したらいいのか迷う…」
            そんな方にも気軽に焙煎を始められる場をつくりました。
            <br/>
            <br/>

            工房では様々なサイズ＆タイプの焙煎機を、全部で３種類ご用意しています。
            生豆もその場で購入できるので、手ぶらでお気軽に、自分の好みの焙煎を追求していただけます。
          </div>
        </div>
      </div>
      <div class="cross-wrapper" style="top: 5vh;">
        <div class="cross-img-group cross-img-group-2"></div>
        <div class="cross-text-group cross-text-group-2">
          <div class="cross-text-title">
            開業を目指す方はもちろん、 どなたでもご利用できます
          </div>
          <div class="cross-text-message">
            こんな方々が焙煎機をご利用いただいてます。ここで同じ想いをもった仲間との出会いもあるかもしれません。
            <br/>
            ●カフェや焙煎所の開業を目指している方
            <br/>
            ●ロースターを目指している方
            <br/>
            ●焙煎機ご購入前に試してみたい方
            <br/>
            ●コーヒーが大好きで自家焙煎を楽しみたい方
            <br/>
            <br/>

            また焙煎機購入への金額感や必要設備、食品衛生などの資格、生豆の保管方法など、コーヒー焙煎関連のお悩みも気軽にご相談ください。
          </div>
        </div>
      </div>
    </div>
    <div class="common-main bg-snow">
      <div class="common-title-group">
        <div class="common-title">
          自分好みの焙煎機を
          <br/>
          見つけてみてください
        </div>
      </div>
      <div class="card-group">
        <div class="arrow arrow-left carousel-left-arrow" v-on:click="activateCarousel"></div>
        <div id="carousel_1" class="card-content" v-bind:class="{'carousel-active-1': isCarouselActive_1}">
          <div class="card-img card-img-1"></div>
          <div class="card-body bg-white">
            <div class="card-sub-title text-green">練習機として最適</div>
            <div class="card-title text-green">ディスカバリー</div>
            <div class="card-message">
              排気ダンパー・豆温度デジタル計・冷却装置など業務用高機能はそのままに、超小型化された、練習機として最適な焙煎機です。
              <br/>
              換気扇で十分な排気もできるので、家庭での趣味用としてもお使いいただけます。
            </div>
            <table>
              <tbody>
              <tr>
                <td class="td-green">メーカー</td>
                <td class="td-green-opacity">富士珈琲</td>
              </tr>
              <tr>
                <td class="td-green">焙煎方法・形式</td>
                <td class="td-green-opacity">半熱風式</td>
              </tr>
              <tr>
                <td class="td-green">熱源</td>
                <td class="td-green-opacity">ガス式</td>
              </tr>
              <tr>
                <td class="td-green">焙煎量</td>
                <td class="td-green-opacity">〜250g</td>
              </tr>
              <tr>
                <td class="td-green">主な装備</td>
                <td class="td-green-opacity">排気装置、排気ダンパー（1から5段階）、生豆ホッパー、豆温度デジタル計、冷却装置、自動点火バーナー、高温警報装置</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="carousel_2" class="card-content" v-bind:class="{'carousel-active-2': isCarouselActive_2}">
          <div class="card-img card-img-2"></div>
          <div class="card-body bg-white">
            <div class="card-sub-title text-green">一生使える耐久性</div>
            <div class="card-title text-green">アポロ</div>
            <div class="card-message">
              音が静かで焙煎機に必要な装備をすべて完備。耐久性に優れ、一生使える焙煎機です。
              <br/>
              ナナハン焙煎機をそのまま小さくし、コンパクトで場所を取らないので、家庭でもお使いいただけます。
            </div>
            <table>
              <tbody>
              <tr>
                <td class="td-green">メーカー</td>
                <td class="td-green-opacity">ワイルドコーヒー</td>
              </tr>
              <tr>
                <td class="td-green">焙煎方法・形式</td>
                <td class="td-green-opacity">半熱風式</td>
              </tr>
              <tr>
                <td class="td-green">熱源</td>
                <td class="td-green-opacity">ガス式</td>
              </tr>
              <tr>
                <td class="td-green">焙煎量</td>
                <td class="td-green-opacity">200〜250g程度</td>
              </tr>
              <tr>
                <td class="td-green">主な装備</td>
                <td class="td-green-opacity">排気ファン、排気ブロワー、排気ダンパー、排気サイクロン、豆温度計、排気温度計、微圧計（オプション）</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="carousel_3" class="card-content" v-bind:class="{'carousel-active-3': isCarouselActive_3}">
          <div class="card-img card-img-3"></div>
          <div class="card-body bg-white">
            <div class="card-sub-title text-green">工房で1番大型</div>
            <div class="card-title text-green">ナナハン</div>
            <div class="card-message">
              メイドインジャパンでしっかりした作りの一生使える焙煎機です。排気ファンの強弱を調整できる機能がついており、ダンパー操作だけではできなかった味のブレが無くなり、仕上がりが一定になります。
            </div>
            <table>
              <tbody>
              <tr>
                <td class="td-green">メーカー</td>
                <td class="td-green-opacity">ワイルドコーヒー</td>
              </tr>
              <tr>
                <td class="td-green">焙煎方法・形式</td>
                <td class="td-green-opacity">直火式</td>
              </tr>
              <tr>
                <td class="td-green">熱源</td>
                <td class="td-green-opacity">ガス式</td>
              </tr>
              <tr>
                <td class="td-green">焙煎量</td>
                <td class="td-green-opacity">250g～900g程度</td>
              </tr>
              <tr>
                <td class="td-green">主な装備</td>
                <td class="td-green-opacity">排気用サイクロン、微圧計、デジタル豆温度計、排気アナログ温度計、急速冷却器、排気ダンパー、排気ファンインバーター、自動点火バーナー</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="arrow arrow-right carousel-right-arrow" v-on:click="activateCarousel"></div>
      </div>
      <div class="btn-group">
        <a class="btn-base btn-basic btn-margin-2 btn-base-large text-white" v-bind:href="reserveRoasterUrl" target="_blank">焙煎機利用 予約</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'use',
  data () {
    return {
      reserveRoasterUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar?category=roaster`,
      isCarouselActive_1: true,
      isCarouselActive_2: false,
      isCarouselActive_3: false
    }
  },
  methods: {
    activateCarousel: function () {
      if (this.isCarouselActive_1) {
        this.isCarouselActive_1 = false
        this.isCarouselActive_2 = true
        this.isCarouselActive_3 = false
      } else if (this.isCarouselActive_2) {
        this.isCarouselActive_1 = false
        this.isCarouselActive_2 = false
        this.isCarouselActive_3 = true
      } else {
        this.isCarouselActive_1 = true
        this.isCarouselActive_2 = false
        this.isCarouselActive_3 = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/what_use/bg_ttl_whatuse.JPG);
    background-position: top 36% center;
  }

  .cross-img-group-1 {
    background-image: url(#{$cf_base_url}/what_use/img_whatuse_01.png);
  }
  .cross-img-group-2 {
    background-image: url(#{$cf_base_url}/what_use/img_whatuse_02.png);
    right: 0;
    top: 0;
  }
  .cross-text-group-2 {
    left: 0 !important;
  }

  .card-img-1 {
    background-image: url(#{$cf_base_url}/what_use/img_roaster_discovery.jpg);
  }
  .card-img-2 {
    background-image: url(#{$cf_base_url}/what_use/img_roaster_aporo.jpg);
  }
  .card-img-3 {
    background-image: url(#{$cf_base_url}/what_use/img_roaster_nanahan.jpg);
  }
  .card-body {
    min-height: 70vh;
  }

  /* MOBILE */
  @media screen and (max-width: 768px) {
    .card-body {
      min-height: 50vh !important;
    }
  }

</style>
