<template>
  <div id="app">
    <common-header></common-header>
    <router-view/>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from './components/CommonHeader'
import CommonFooter from './components/CommonFooter'

export default {
  components: { CommonHeader, CommonFooter }
}
</script>

<style lang="scss">
  @import "~bootstrap-css-only/css/bootstrap.min.css";

  @import 'assets/sass/global';

  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700|Roboto:400,500,700&display=swap');

  html, body {
    font-family: 'Roboto', 'Noto Serif', 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",メイリオ,Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    margin: 0;
    border: 0;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: 0.02em;
    color: $text_black
  }
  button {
    border: 0;
  }
  input, textarea {
    text-indent: 0.5em;
    width: 100%;
    font-family: 'Roboto', 'Noto Serif', 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",メイリオ,Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 0.02em;
    text-align: left;
    color: $text_black;
    background-color: $bg_snow;
    border: $bg_snow;
  }
  textarea {
    display: block;
    padding: 2vh 1vw 2vh 1vw;
    margin: 0;
  }
  a {
    color: $text_black;
    text-decoration: none;
  }
  a:hover {
    color: $text_black;
  }
  p {
    letter-spacing: 0.02em;
  }

  #app {
    margin: 0 auto;
    // min-width: 960px;
  }
  .content {
    overflow: hidden;
  }

  /* UTILITY */
  .with-center {
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
  }
  .with-vertical-center {
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .with-horizon-center {
    -webkit-justify-content: center !important; /* 横方向中央揃え（Safari用） */
    justify-content: center !important; /* 横方向中央揃え */
  }
  .wrapper-row {
    display: flex;
    flex-direction: row !important;
  }
  .wrapper-column {
    display: flex;
    flex-direction: column !important;
  }
  .pd-top-5 {
    padding-top: 5vh !important;
  }
  .pd-btm-2 {
    padding-bottom: 2vh !important;
  }
  .from-bottom-3 {
    position: absolute;
    bottom: 3vh;
    left: 4vw;
    margin: auto;
  }
  .is-visible {
    display: block !important;
  }
  .arrow{
    position: relative;
    display: inline-block;
    padding: 0 0 0 0;
    color: $bg_black;
    vertical-align: middle;
    text-decoration: none;
    font-size: 10px;
  }
  .arrow::before,
  .arrow::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }
  .arrow-left {
    left: 1vw;
    right: 1vw;
    width: 3vw;
    height: 3vw;
    border-top: 1px solid $bg_black;
    border-right: 1px solid $bg_black;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .arrow-right {
    left: -1vw;
    right: -1vw;
    width: 3vw;
    height: 3vw;
    border-top: 1px solid $bg_black;
    border-right: 1px solid $bg_black;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* 背景色 */
  .bg-white {
    background-color: $bg_white !important;
  }
  .bg-snow {
    background-color: $bg_snow !important;
  }
  .bg-gray {
    background-color: $bg_cream !important;
  }

  /* 見出し・本文欧文：Noto Serif */
  .text-headline-en {
    line-height: 1;
    color: $text_black
  }

  .text-normal-en {
    line-height: 1;
    color: $text_black
  }

  /* 見出し・和文：Noto sans japanese bold（700）*/
  .text-headline-ja {
    line-height: 1;
    color: $text_black
  }

  .text-normal-ja {
    font-size: 0.9vw;
    line-height: 1.28;
    color: $text_black
  }

  .text-black {
    color: $text_black !important;
  }
  .text-white {
    color: $text_white !important;
  }
  .text-yellow {
    color: $text_yellow;
  }
  .text-green {
    color: $text_green;
  }
  .text-dark-green {
    color: $text_dark_green;
  }
  .text-red{
      color: red;
  }

  /* BUTTON */
  .booking-button-group {
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  $btn_base_height: 5.5vh;
  $btn_base_width: 12vw;
  .btn-group {
    padding: 0vh 0vw 0vh 0vw;
    min-width: $btn_base_width;
    min-height: $btn_base_height;
    margin: auto;
  }
  .btn-base {
    height: $btn_base_height;
    width: $btn_base_width;
    font-size: 0.7vw;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
  }
  .btn-margin-2 {
    margin: 1vh 1vw 1vh 1vw;
  }
  .btn-margin-both-2 {
    margin: 0vh 2vw 0vh 2vw;
  }
  .btn-base-large {
    height: 7vh !important;
    width: 20vw !important;
    font-size: 1vw;
  }

  /* ボタン（基本） */
  .btn-basic {
    border: solid 1px $bg_black;
    background-color: $bg_black;
    color: $text_white;
  }
  .btn-basic:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
    -ms-filter:"alpha( opacity=70 )";
  }

  /* ボタン（予約） */
  .btn-booking {
    border: solid 1px $bg_black;
    background-color: $bg_white;
  }
  .btn-booking:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
    -ms-filter:"alpha( opacity=70 )";
  }

  /* ボタン（体験） */
  .btn-beginner {
    background-color: $bg_yellow;
  }
  .btn-beginner-img {
    background-image: url(#{$cf_base_url}/common/ic_beginner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 1.5vw;
    width: 1vw;
    z-index: 1;
    margin-right: 0.5vw;
  }
  .btn-beginner:hover {
    opacity: 0.7;
    text-decoration: none;
    color: $bg_black;
    filter:alpha(opacity=70);
    -ms-filter:"alpha( opacity=70 )";
  }
  .btn-beginner-md {
    height: 6vh;
    width: 12vw;
    font-size: 0.9vw;
    font-weight: normal;
    margin: auto;
  }
  .btn-beginner-lg {
    height: 9vh;
    width: 20vw;
    font-size: 1.2vw;
    margin: auto;
  }
  .btn-snow {
    background-color: $bg_snow !important;
    color: $text_cream !important;
    border: 0;
    cursor: not-allowed;
  }
  .btn-gray {
    background-color: $bg_gray;
    color: $text_white;
  }
  .btn-gray:hover {
    opacity: 0.7;
    filter:alpha(opacity=70);
    -ms-filter:"alpha( opacity=70 )";
  }

  /* CONTENT_HEADER_LAPTOP */
  $content_header_height: 40vh;
  $padding_content_header_title: calc(#{$content_header_height} / 2 + 2vh);
  .content-header {
    position: relative;
    max-height: $content_header_height;
    min-height: $content_header_height;
    height: $content_header_height;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .content-header::before {
    content: "";
    position: absolute;
    top: 0vh;
    left: 0;
    width: 100%;
    height: $content_header_height;
    background: rgba(0, 0, 0, 0.3);
  }
  .content-header-title-group {
    display: flex;
    flex-direction: column;
    color: $text_white;
    text-align: center;
    padding: $padding_content_header_title 0vw 0vh 0vw;
  }
  .content-header-title {
    font-size: 2.0vw;
    font-weight: bold;
    letter-spacing: 0.2em;
    z-index: 100 !important;
  }
  .content-header-sub-title {
    font-size: 0.8vw;
    letter-spacing: 0.2em;
    line-height: 1.5;
    z-index: 100 !important;
  }

  /* MAIN_LAPTOP */
  .common-main {
    width: 100%;
    padding: 10vh 0vw 10vh 0vw;
    background-color: $bg_white;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    -webkit-align-items: center;
  }
  .common-title-group {
    margin: auto;
    text-align: center;
  }
  .common-title {
    font-size: 1.6vw;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.1em !important;
  }
  .common-subtitle {
    font-size: 0.8vw;
    line-height: 2;
    text-align: left;
    letter-spacing: 0.025em !important;
    padding: 3vh 0vw 0vh 0vw;
    max-width: 40vw;
  }
  .common-subtitle-bold {
    font-size: 0.7vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.025em !important;
    padding: 0vh 0vw 0vh 0vw;
  }

  /* BORDER_LINE_LAPTOP */
  .border-line-group {
    padding-top: 5vh;
  }
  .border-line {
    width: 50vw;
    margin: auto;
    border: solid 1px $bg_light_gray;
    opacity: 0.5;
  }

  /* CROSS_LAPTOP */
  $cross_img_height: 33vh;
  $cross_img_width: 30vw;
  $cross_total_height: calc(#{$cross_img_height} * 2 - 8vh);
  $cross_text_width: 25vw;

  .cross-wrapper {
    position: relative;
    width: 50%;
    height: $cross_total_height;
    margin: auto;
    padding: 5vh 0vw 5vh 0vw;
  }
  .cross-img-group {
    position: absolute;
    padding: 3vh 0vw 3vh 0vw;
    z-index: 100;
    height: $cross_img_height;
    width: $cross_img_width;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cross-text-group {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $cross_text_width;
    min-height: $cross_img_height;
    background: rgba(255,255,255,.8);
    padding: 3vh 0vw 2vh 0vw;
    z-index: 200;
  }
  .cross-text-title {
    font-weight: bold;
    font-size: 1.2vw;
    padding: 2vh 3vw 3vh 3vw;
    line-height: 1.8;
  }
  .cross-text-message {
    font-size: 0.8vw;
    line-height: 2;
    padding: 2vh 3vw 3vh 3vw;
  }

  /* CARD_LAPTOP */
  .card-group {
    display: flex;
    flex-direction: row;
    padding: 4vh 0vw 4vh 0vw !important;
    margin: auto;
  }
  .card-group-bordered {
    padding: 4vh 0vw 4vh 0vw !important;
    width: 50vw;
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
    border: solid 5px $bg_snow;
  }
  .card-content {
    padding: 0vh 1vw 0vh 1vw;
    width: 20vw;
  }
  .arrow-group {
    display: none;
  }
  .carousel-left-arrow {
    display: none;
  }
  .carousel-right-arrow {
    display: none;
  }
  .carousel-active-1 {
    display: block;
  }
  .carousel-active-2 {
    display: block;
  }
  .carousel-active-3 {
    display: block;
  }
  .carousel-active-4 {
    display: block;
  }
  .carousel-active-5 {
    display: block;
  }
  .card-content-sm {
    width: 13vw !important;
    padding: 0vh 1vw 0vh 1vw;
  }
  .card-content-md {
    width: 40vw !important;
  }
  .card-content-lg {
    width: 50vw !important;
    padding: 2vh 0vw 2vh 0vw;
  }
  .card-bordered {
    border: solid 5px $bg_snow;
  }
  .card-img-group {
  }
  $card_img_width: 20vw;
  .cb-card-img {
    width: $card_img_width;
    height: 30vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .cb-card-img::after {
    content: "";
    position: relative;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
  .card-img-sm {
    width: 13vw;
    height: 19vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .card-body {
    position: relative;
    background-color: $bg_snow;
    padding: 3vh 0vw 3vh 0vw;
    min-height: 40vh;
  }
  .card-title-group {
    padding: 25% 0vw 25% 0vw;
    font-weight: bold;
    color: $text_white;
    z-index: 100 !important;
  }
  .card-sub-title {
    font-size: 0.8vw;
    line-height: 2;
    text-align: center;
  }
  .card-title-sm {
    font-size: 1vw;
    line-height: 2;
    text-align: center;
  }
  .card-title {
    font-size: 1.2vw;
    line-height: 2;
    text-align: center;
    font-weight: bold;
  }
  .card-message {
    font-size: 0.8vw;
    padding: 2vh 2vw 3vh 2vw;
    text-align: left;
    line-height: 2;
  }

  /* LABEL_BORDER_LAPTOP */
  .label-border {
    background-color: $bg_yellow;
    border-radius: 1vw;
    height: 3.5vh;
    max-width: 12vw;
    margin: auto;
  }
  .label-border p {
    text-align: center;
    font-size: 0.7vw;
    font-weight: lighter;
    color: $text_black;
    padding: 0vh 1vw 0vh 1vw;
    line-height: 2.8;
    margin: 0;
  }

  /* PRICE_LAPTOP */
  .price-message-group {
    display: flex;
    flex-direction: row;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: baseline; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 0vh 0vw 0vh 0vw;
  }
  .price-value {
    font-size: 2.4vw;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.02em;
  }
  .price-value-small {
    font-size: 2.0vw;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.02em;
  }
  .price-unit {
    font-size: 1.2vw;
    line-height: 2;
    text-align: center;
    margin-left: 0.2vw;
    font-weight: bold;
  }

  /* TABLE_LAPTOP */
  table {
    margin: 0vh 2vw 0vh 2vw;
    border-collapse: collapse;
  }
  td {
    min-width: 6vw;
    border-top: solid 0.2em $bg_white;
  }
  .td-green {
    text-align: center;
    background-color: $bg_green;
    font-size: 0.7vw;
    line-height: 3;
    color: $text_white;
  }
  .td-green-opacity {
    text-align: left;
    background-color: rgba(133, 174, 168, 0.1);
    font-size: 0.7vw;
    line-height: 2;
    padding: 0em 1em 0em 1em;
    color: $text_black;
  }

  /* MOBILE */
  @media screen and (max-width: 768px) {
    html, body {
      font-size: $fz_sp_common !important;
      line-height: 1.28;
      letter-spacing: 0.02em;
    }
    p {
      letter-spacing: 0.02em;
    }
    textarea {
      font-size: $fz_sp_common;
    }

    /* UTILITY_MOBILE */
    .from-bottom-3 {
      position: absolute;
      bottom: 3vh;
      left: 10vw;
      margin: auto;
    }

    /* MAIN_MOBILE */
    .common-main {
      padding: 4vh 0vw 4vh 0vw !important;
      width: 100vw;
      display: flex;
      flex-direction: column;
    }
    .common-title-group {
      padding: 0% 0vw 0% 0vw !important;
      max-width: 80%;
    }
    .common-title {
      font-size: 5vw !important;
    }
    .common-subtitle {
      font-size: $fz_sp_common !important;
      max-width: 100%;
      margin: auto;
    }
    .common-subtitle-bold {
      font-size: 2vw !important;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.025em !important;
      padding: 0vh 0vw 0vh 0vw;
    }

    /* BUTTON_MOBILE */
    $btn_base_height: 8vh;
    $btn_base_width: 70vw;
    .btn-group {
      padding: 0vh 0vw 0vh 0vw;
      width: $btn_base_width;
      min-height: $btn_base_height;
      margin: auto;
    }
    .btn-base {
      height: $btn_base_height;
      width: $btn_base_width;
      font-size: $fz_sp_btn_base !important;
    }
    .btn-base-large {
      height: $btn_base_height !important;
      width: $btn_base_width !important;
      font-size: 1vw;
    }
    .btn-beginner-lg {
      font-size: 4vw !important;
    }
    .btn-beginner-img {
      background-size: contain;
      height: 4.3vw;
      width: 4.3vw;
      z-index: 1;
      margin-right: 1vw;
    }

    /* CONTENT_HEADER_MOBILE */
    $content_header_height: 30vh;
    $padding_content_header_title: calc(#{$content_header_height} / 2 + 2vh);
    .content-header {
      max-height: $content_header_height;
      min-height: $content_header_height;
      height: $content_header_height;
    }
    .content-header::before {
      height: $content_header_height;
    }
    .content-header-title-group {
      padding: $padding_content_header_title 0vw 0vh 0vw !important;
    }
    .content-header-title {
      font-size: $fz_sp_header_title;
      font-weight: bold;
    }
    .content-header-sub-title {
      font-size: $fz_sp_header_sub_title;
    }

    /* CROSS_MOBILE */
    $cross_img_height: 33vh;
    $cross_img_width: 100vw;
    $cross_total_height: 120vh;
    $cross_text_width: 25vw;

    .cross-wrapper {
      position: relative;
      width: 100%;
      max-height: $cross_total_height;
      height: fit-content;
      margin: auto;
      padding: 5vh 0vw 0vh 0vw;
    }
    .cross-img-group {
      position: static;
      padding: 3vh 0vw 3vh 0vw;
      z-index: 100;
      height: $cross_img_height;
      width: $cross_img_width;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .cross-text-group {
      position: static;
      min-height: $cross_img_height;
      background: rgba(255,255,255,.8);
      padding: 3vh 5vw 2vh 5vw !important;
      width: 90%;
      z-index: 200;
      text-align: center;
    }
    .cross-text-title {
      font-weight: bold;
      font-size: 5vw;
      padding: 2vh 3vw 2vh 3vw;
      line-height: 1.8;
    }
    .cross-text-message {
      font-size: $fz_sp_common;
      text-align: left;
    }

    /* CARD_MOBILE */
    .card-group {
      position: relative; //TODO CAUTION!!!
      padding: 4vh 0vw 4vh 0vw !important;
      display: flex;
      flex-direction: row;
      margin: auto;
      width: 100%;
      align-items: center;
    }
    .card-group-bordered {
      padding: 2vh 0vw 2vh 0vw !important;
      display: flex;
      flex-direction: row;
      margin: auto;
      width: 90vw;
      border: solid 5px $bg_snow;
    }
    $card_content_width: 90vw;
    .card-content {
      padding: 0vh 0vw 0vh 0vw;
      width: $card_content_width !important;
      display: none;
      margin: auto;
    }
    .card-content-sm {
      position: relative;
      width: 84vw !important;
      display: flex;
      flex-direction: row !important;
      padding: 2vh 1vw 2vh 1vw;
    }
    .card-content-sm:before {
      content: "";
      position: absolute;
      bottom: 0vh;
      left: 4vw;
      border-bottom: solid 1px $bg_gray;
      width: 80vw;
    }
    .card-content-md {
      width: 40vw !important;
    }
    .card-content-lg {
      width: 90vw !important;
      padding: 2vh 0vw 2vh 0vw;
    }
    $card_img_width: $card_content_width;
    .card-img {
      width: $card_img_width;
      height: 30vh;
    }
    .card-img-sm {
      width: calc(#{$card_img_width} /2 + 5vw) !important;
      margin: 0;
      background-size: contain !important;
    }
    .card-body {
      padding: 3vh 0vw 3vh 0vw;
      min-height: 3vh;
    }
    .card-title-group {
    }
    .card-sub-title {
      font-size: $fz_sp_header_sub_title !important;
      font-weight: normal !important;
      line-height: 2;
    }
    .card-title-sm {
      font-size: 2.5vw;
      text-align: center;
    }
    .card-title {
      font-size: $fz_sp_header_title;
      font-weight: bold;
    }
    .card-message {
      font-size: $fz_sp_common !important;
      padding: 2vh 5vw 2vh 5vw;
    }

    /* CAROUSEL_MOBILE */
    .arrow-group {
      display: block;
      position: absolute;
      top: 0vh;
      padding-top: 5vh;
      height: 100vh !important;
      -webkit-align-items: center !important;
      align-items: center !important;
    }
    .carousel-left-arrow {
      display: block !important;
    }
    .carousel-right-arrow {
      display: block !important;
    }
    .carousel-active-1 {
      display: block !important;
    }
    .carousel-active-2 {
      display: block !important;
    }
    .carousel-active-3 {
      display: block !important;
    }
    .carousel-active-4 {
      display: block !important;
    }
    .carousel-active-5 {
      display: block !important;
    }

    /* LABEL_BORDER_MOBILE */
    .label-border {
      border-radius: 2.5vh;
      height: 5vh;
      min-width: 40vw;
      align-items: center;
      align-content: center;
      margin: auto;
    }
    .label-border p {
      font-size: 2vw;
      line-height: 4;
    }

    /* PRICE_MOBILE */
    .price-message-group {
      display: flex;
      flex-direction: row;
      padding: 2vh 0vw 2vh 0vw;
    }
    .price-value {
      font-size: 2.4vw;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.02em;
    }
    .price-value-small {
      font-size: 6vw;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.02em;
    }
    .price-unit {
      font-size: 1.2vw;
      line-height: 2;
      text-align: center;
      margin-left: 0.2vw;
      font-weight: bold;
    }

    /* BORDER_LINE_LAPTOP */
    .border-line-group {
      padding-top: 0vh;
    }
    .border-line {
      width: 90vw;
      margin: auto;
      border: solid 1px $bg_light_gray;
      opacity: 0.5;
    }

    /* TABLE_LAPTOP */
    table {
      margin: auto;
      width: 80vw;
      border-collapse: collapse;
    }
    td {
      min-width: 12vw;
      border-top: solid 0.2em $bg_white;
    }
    .td-green {
      text-align: center;
      background-color: $bg_green;
      font-size: 2vw;
      line-height: 3;
      color: $text_white;
    }
    .td-green-opacity {
      text-align: left;
      background-color: rgba(133, 174, 168, 0.1);
      font-size: 2vw;
      line-height: 2;
      padding: 0em 1em 0em 1em;
      color: $text_black;
    }
  }

  @media screen and (max-width: 735px) and (max-height: 1000px) {
    #app {
      overflow: hidden;
      min-width: 330px;
    }
    .content {
    }
    .common-main {
      padding: 8vh 0vw 8vh 0vw;
      width: 100vw;
      display: flex;
      flex-direction: column;
    }
  }
</style>
