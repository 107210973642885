<template>
  <div class="privacy-policy">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title"></div>
        <div class="content-header-sub-title"></div>
      </div>
    </div>

     <div class="row h-75" style="height: 30vh !important;">
        <div class="col d-flex flex-column text-center py-5">
          <p class="p-strong">お探しのページが見つかりませんでした。</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped lang="scss">
  @import '../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/privacy/bg_ttl_privacy.JPG);
    background-position: top 58% center;
  }

</style>
