<template>
  <div class="privacy-policy">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">PRIVACY POLICY</div>
        <div class="content-header-sub-title">プライバシーポリシー</div>
      </div>
    </div>
    <div class="common-main">
      <div class="common-title-group">
        <div class="common-subtitle text-black">
          ぼくらの焙煎工房は、皆様の情報を保護し、その信頼を確保することに社会的責任を負っております。
          <br/>
          ぼくらの焙煎工房の業務に従事しておりますすべてのスタッフは、皆様の個人情報や営業秘密を安全に取り扱うことで、皆様の情報を守り、その信頼に応えるように努めます。
          <br/>
          <br/>

          ○個人情報保護法および関連するその他の法令及び規範を遵守します。
          <br/>
          ○個人情報を利用する場合は、当社の事業目的を達成する範囲内でのみ利用・提供・委託します。
          <br/>
          ○個人情報をまもる為の改善の努力を日々いたします。
          <br/>
          ○個人情報への不正アクセスや、個人情報の漏えい、紛失、破壊、改ざん等に対して、適切な対応をいたします。
          <br/>
          ○個人情報に関するお問い合わせ、開示等のご請求に誠実かつ迅速に対応します。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped lang="scss">
  @import '../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/privacy/bg_ttl_privacy.JPG);
    background-position: top 58% center;
  }

</style>
