<template>
  <div class="contact">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">CONTACT</div>
        <div class="content-header-sub-title">お問い合わせ</div>
      </div>
    </div>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <p>
            ご不明な点や、サイトにてご案内以外の用途ご相談も
            <br/>
            お気軽にお問い合わせください。
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-center py-3">
        <div class="col-sm-10 col-md-5">
          <!-- Organization Name -->
          <div class="form-group py-1">
            <div class="form-label">
              貴社名・店舗名
            </div>
            <input class="form-control" placeholder="例）ワイエスプランニング" v-model="form.organization.textValue">
          </div>
          <!-- Name -->
          <div class="form-group py-1">
            <div class="form-label d-flex flex-row my-2">
              お名前
              <div class="badge badge-danger">必須</div>
            </div>
            <input class="form-control" placeholder="例）山田太郎" v-bind:class="{ 'chk-form-invalid': !form.name.isValid }" v-model="form.name.textValue">
          </div>
          <!-- Name Rubi -->
          <div class="form-group py-1">
            <div class="form-label d-flex flex-row my-2">
              お名前（フリガナ）
              <div class="badge badge-danger">必須</div>
            </div>
            <input class="form-control" placeholder="例）ヤマダタロウ" v-bind:class="{ 'chk-form-invalid': !form.name_rubi.isValid }" v-model="form.name_rubi.textValue">
          </div>
          <!-- EMail -->
          <div class="form-group py-1">
            <div class="form-label d-flex flex-row my-2">
              メールアドレス
              <div class="badge badge-danger">必須</div>
            </div>
            <input class="form-control" placeholder="例）ysplaning@mail.com" v-bind:class="{ 'chk-form-invalid': !form.email.isValid }" v-model="form.email.textValue">
          </div>
          <!-- Comment -->
          <div class="form-group py-1">
            <div class="form-label d-flex flex-row my-2">
              お問い合わせ内容
              <div class="badge badge-danger">必須</div>
            </div>
            <textarea class="form-control" placeholder="ご自由にご記入ください" rows="5" v-bind:class="{ 'chk-form-invalid': !form.email.isValid }" v-model="form.comment.textValue"></textarea>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col d-flex justify-content-center">
          <div class="alert alert-warning col-md-5 d-flex flex-row justify-content-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-bind:value="checkPrivacyPolicy" v-on:click="updatePolicy">
            </div>
            <div class="chk-pp-label mx-2"><span style="text-decoration: underline;">プライバシーポリシー</span>（個人情報の取り扱い）に同意する</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="btn-group">
            <div class="btn-base btn-snow text-normal-ja"
                 v-on:click="proceedConfirm" v-bind:class="{ 'chk-pp-btn-base': checkPrivacyPolicy }">
              入力内容を確認する
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact',
  data () {
    return {
      form: {
        organization: { textValue: '', isValid: true },
        name: { textValue: '', isValid: true },
        name_rubi: { textValue: '', isValid: true },
        email: { textValue: '', isValid: true },
        comment: { textValue: '', isValid: true }
      },
      activeColor: 'black',
      checkPrivacyPolicy: false
    }
  },
  methods: {
    updatePolicy: function () {
      if (this.checkPrivacyPolicy) {
        this.checkPrivacyPolicy = false
        this.activeColor = 'white'
      } else {
        this.checkPrivacyPolicy = true
        this.activeColor = 'black'
      }
    },
    proceedConfirm: function () {
      let checkFlg = true
      if (this.form.name.textValue === '') {
        this.form.name.isValid = false
        checkFlg = false
      } else {
        this.form.name.isValid = true
      }
      if (this.form.name_rubi.textValue === '') {
        this.form.name_rubi.isValid = false
        checkFlg = false
      } else {
        this.form.name_rubi.isValid = true
      }
      if (this.form.email.textValue === '') {
        this.form.email.isValid = false
        checkFlg = false
      } else {
        this.form.email.isValid = true
      }
      if (this.form.comment.textValue === '') {
        this.form.comment.isValid = false
        checkFlg = false
      } else {
        this.form.comment.isValid = true
      }

      if (checkFlg) {
        this.$store.commit('updateContactInfo', this.form)
        this.$router.push('/contact/confirm')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/contact/bg_ttl_contact.JPG);
    background-position: top 70% center;
  }

  .common-subtitle {
    text-align: center;
  }

  .chk-pp {
    padding: 5vh 0vw 5vh 0vw;
    height: 10vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
  }
  .chk-pp-content {
    position: relative;
    width: 100%;
    max-width: 40vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    background-color: $bg_white;
    border: solid 2px $bg_cream;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .chk-pp-circle {
    position: relative;
    left: 2vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background: $bg_snow;
    border: solid 1px $bg_gray;
  }
  .chk-pp-circle:hover {
    background: $bg_black;
    border: solid 3px #cccccc;
    opacity: 0.8;
  }
  .chk-pp-checked {
    background: $bg_black;
    border: solid 3px #cccccc;
  }
  .chk-pp-label {
    position: relative;
    font-size: 1vw;
    line-height: 2;
    letter-spacing: 0.01em;
    text-align: left;
    color: $bg_black;
  }
  .chk-pp-btn-base {
    border: solid 1px $bg_black;
    background-color: $bg_black !important;
    color: $text_white;
    cursor: pointer;
  }
  .chk-pp-btn-base:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
    -ms-filter:"alpha( opacity=70 )";
  }
  .chk-form-invalid {
    border: solid 1px red;
  }

  /* MOBILE */
  @media screen and (max-width: 768px) {
    .common-subtitle {
      text-align: left;
    }
    .common-title-group {
      padding: 0% 0vw 0% 0vw !important;
      width: 80%;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      width: 80vw;
      margin: auto;
    }
    .form-label-group {
    }
    .form-label-name {
      font-size: $fz_sp_common;
    }
    .form-label-require {
      font-size: $fz_sp_header_sub_title !important;
      line-height: 1;
      letter-spacing: 0.05em;
      height: $fz_sp_header_sub_title;
      width: 10vw;
    }
    .form-input-group {
      margin: 0;
    }
    .form-input {
      font-size: $fz_sp_common;
    }
    .form-textarea {
      width: 78vw;
      font-size: 2.5vw;
    }
    .chk-pp {
      width: 80vw !important;
    }
    .chk-pp-content {
      position: relative;
      max-width: 80vw;
    }
    .chk-pp-circle {
      left: 5vw;
      width: 3vw;
      height: 3vw;
    }
    .chk-pp-label {
      font-size: $fz_sp_common;
    }
  }
</style>
