<template>
  <div class="buy">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-sub-title">コーヒー生豆を</div>
        <div class="content-header-title">買える</div>
      </div>
    </div>
    <div class="common-main">
      <div class="common-title-group">
        <div class="text-headline-ja common-title">
          人気産地の生豆もその場で購入
          <br />
          だから、手ぶらで焙煎
        </div>
        <div class="text-headline-ja common-subtitle">
          焙煎機を利用いただく方には、いつでも気軽に手ぶらで工房へ来ていただきたいから。南米・アフリカ・東南アジアと、コーヒーベルトの中でも有名な３つの産地を中心とした「生豆」もご提供しています。
        </div>
      </div>
      <div class="cross-wrapper">
        <div class="cross-img-group cross-img-group-1"></div>
        <div class="cross-text-group">
          <div class="cross-text-title">
            高品質の生豆を焙煎し比べて、自分好みを追求。
          </div>
          <div class="cross-text-message">
            工房の運営会社では生豆の卸販売も行っているため、そこで取り扱っている有名産地を中心とした、全５種類の高品質な生豆をその場でご購入いただけます。
            <br />
            それぞれの産地で異なる、香り・味わい・酸味を比べてみてください。きっとあなたのお好みの生豆が見つかると思います。
            <br />
            もちろん、ご自身でこだわりの生豆をお持ち込みいただくことも可能です。
          </div>
        </div>
      </div>
    </div>
    <div class="common-main bg-snow">
      <div class="common-title-group">
        <div class="common-title">
          焙煎＆飲み比べして
          <br />
          新しいコーヒー生豆との出会いを
        </div>
        <div class="common-subtitle">
          工房で取り扱っている5つの産地の生豆をご紹介します。意外にその産地が世界のどこにあるのか、知らない方も多いのではないでしょうか？どんな場所で育てられたのか、想像しながら楽しんでみてください。
        </div>
      </div>
      <div class="coffee-in-the-world-map-group">
        <div class="coffee-in-the-world-map"></div>
      </div>
      <div class="coffee-card-group">
        <div
          class="arrow arrow-left carousel-left-arrow"
          v-on:click="activateCarousel"
        ></div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-1': isCarouselActive_1 }"
        >
          <div class="country-flag country-flag-columbia"></div>
          <div class="card-body">
            <div class="card-sub-title">おだやかな酸味</div>
            <div class="card-title">コロンビア</div>
            <div class="price-message-group">
              <div class="price-value">600</div>
              <div class="price-unit">円/100g（税込）</div>
            </div>
            <div class="radar-chart radar-chart-columbia"></div>
            <div class="card-message text-black">
              おだやかな酸味とクリーンカップ豊かな甘味で、なめらかな舌触りになります。
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="td-green">品種</td>
                  <td class="td-green-opacity">アラビカ種</td>
                </tr>
                <tr>
                  <td class="td-green">製法（精製）</td>
                  <td class="td-green-opacity">Washed</td>
                </tr>
                <tr>
                  <td class="td-green">保管</td>
                  <td class="td-green-opacity">定温倉庫</td>
                </tr>
                <tr>
                  <td class="td-green">
                    おすすめの
                    <br />
                    焙煎方法
                  </td>
                  <td class="td-green-opacity">
                    ミディアムロースト～フルシティロースト
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-2': isCarouselActive_2 }"
        >
          <div class="country-flag country-flag-guatemala"></div>
          <div class="card-body">
            <div class="card-sub-title">甘い香りとナッツ系の風味</div>
            <div class="card-title">グアテマラ</div>
            <div class="price-message-group">
              <div class="price-value">600</div>
              <div class="price-unit">円/100g（税込）</div>
            </div>
            <div class="radar-chart radar-chart-guatemala"></div>
            <div class="card-message text-black">
              ナチュラル独特の甘い香りとナッツ系の風味があり、深煎りでビターチョコのフレーバーがあります。
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="td-green">品種</td>
                  <td class="td-green-opacity">アラビカ種</td>
                </tr>
                <tr>
                  <td class="td-green">製法（精製）</td>
                  <td class="td-green-opacity">Washed</td>
                </tr>
                <tr>
                  <td class="td-green">保管</td>
                  <td class="td-green-opacity">定温倉庫</td>
                </tr>
                <tr>
                  <td class="td-green">
                    おすすめの
                    <br />
                    焙煎方法
                  </td>
                  <td class="td-green-opacity">
                    ミディアムロースト～フルシティロースト
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-3': isCarouselActive_3 }"
        >
          <div class="country-flag country-flag-kenya"></div>
          <div class="card-body">
            <div class="card-sub-title">柑橘系の酸味と力強さ</div>
            <div class="card-title">ケニア</div>
            <div class="price-message-group">
              <div class="price-value">750</div>
              <div class="price-unit">円/100g（税込）</div>
            </div>
            <div class="radar-chart radar-chart-kenya"></div>
            <div class="card-message text-black">
              柑橘系の酸味とマイルドで力強いケニア。ボディ感に加え甘い後味が特徴です。
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="td-green">品種</td>
                  <td class="td-green-opacity">アラビカ種</td>
                </tr>
                <tr>
                  <td class="td-green">製法（精製）</td>
                  <td class="td-green-opacity">Washed</td>
                </tr>
                <tr>
                  <td class="td-green">保管</td>
                  <td class="td-green-opacity">定温倉庫</td>
                </tr>
                <tr>
                  <td class="td-green">
                    おすすめの
                    <br />
                    焙煎方法
                  </td>
                  <td class="td-green-opacity">
                    ミディアムロースト～フルシティロースト
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-4': isCarouselActive_4 }"
        >
          <div class="country-flag country-flag-ethiopia"></div>
          <div class="card-body">
            <div class="card-sub-title">フルーツのような香り</div>
            <div class="card-title">エチオピア</div>
            <div class="price-message-group">
              <div class="price-value">750</div>
              <div class="price-unit">円/100g（税込）</div>
            </div>
            <div class="radar-chart radar-chart-ethiopia"></div>
            <div class="card-message text-black">
              独特の甘みとフルーツのような香りがあります。
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="td-green">品種</td>
                  <td class="td-green-opacity">アラビカ種</td>
                </tr>
                <tr>
                  <td class="td-green">製法（精製）</td>
                  <td class="td-green-opacity">Washed</td>
                </tr>
                <tr>
                  <td class="td-green">保管</td>
                  <td class="td-green-opacity">定温倉庫</td>
                </tr>
                <tr>
                  <td class="td-green">
                    おすすめの
                    <br />
                    焙煎方法
                  </td>
                  <td class="td-green-opacity">
                    ミディアムロースト～フルシティロースト
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-5': isCarouselActive_5 }"
        >
          <div class="country-flag country-flag-indonesia"></div>
          <div class="card-body">
            <div class="card-sub-title">ビターチョコの風味</div>
            <div class="card-title">インドネシア</div>
            <div class="price-message-group">
              <div class="price-value">650</div>
              <div class="price-unit">円/100g（税込）</div>
            </div>
            <div class="radar-chart radar-chart-indonesia"></div>
            <div class="card-message text-black">
              深煎りでビターチョコの風味と濃厚な甘みがあります。
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="td-green">品種</td>
                  <td class="td-green-opacity">アラビカ種</td>
                </tr>
                <tr>
                  <td class="td-green">製法（精製）</td>
                  <td class="td-green-opacity">Washed</td>
                </tr>
                <tr>
                  <td class="td-green">保管</td>
                  <td class="td-green-opacity">定温倉庫</td>
                </tr>
                <tr>
                  <td class="td-green">
                    おすすめの
                    <br />
                    焙煎方法
                  </td>
                  <td class="td-green-opacity">
                    ミディアムロースト～フルシティロースト
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="arrow arrow-right carousel-right-arrow"
          v-on:click="activateCarousel"
        ></div>
      </div>
      <div class="card-group text-dark-green">
        <div class="card-content is-visible card-content-md bg-white">
          <div class="ic-bean"></div>
          <div class="price-headline-ja">
            こだわり生豆での持ち込み焙煎も可能です
          </div>
          <div class="card-title price-notification-title">
            生豆持ち込み費用
          </div>
          <div class="price-message-group">
            <div class="price-value">550</div>
            <div class="price-unit">円/回（税込）</div>
          </div>
          <div class="card-sub-title price-notification">
            ※異物が入ってないかは、事前にご自身でご確認をお願いします。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'buy',
  data() {
    return {
      reserveSeminarUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar`,
      isCarouselActive_1: true,
      isCarouselActive_2: false,
      isCarouselActive_3: false,
      isCarouselActive_4: false,
      isCarouselActive_5: false,
    };
  },
  methods: {
    activateCarousel: function () {
      if (this.isCarouselActive_1) {
        this.isCarouselActive_1 = false;
        this.isCarouselActive_2 = true;
        this.isCarouselActive_3 = false;
        this.isCarouselActive_4 = false;
        this.isCarouselActive_5 = false;
      } else if (this.isCarouselActive_2) {
        this.isCarouselActive_1 = false;
        this.isCarouselActive_2 = false;
        this.isCarouselActive_3 = true;
        this.isCarouselActive_4 = false;
        this.isCarouselActive_5 = false;
      } else if (this.isCarouselActive_3) {
        this.isCarouselActive_1 = false;
        this.isCarouselActive_2 = false;
        this.isCarouselActive_3 = false;
        this.isCarouselActive_4 = true;
        this.isCarouselActive_5 = false;
      } else if (this.isCarouselActive_4) {
        this.isCarouselActive_1 = false;
        this.isCarouselActive_2 = false;
        this.isCarouselActive_3 = false;
        this.isCarouselActive_4 = false;
        this.isCarouselActive_5 = true;
      } else {
        this.isCarouselActive_1 = true;
        this.isCarouselActive_2 = false;
        this.isCarouselActive_3 = false;
        this.isCarouselActive_4 = false;
        this.isCarouselActive_5 = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/global";

.content-header {
  background-image: url(#{$cf_base_url}/what_buy/bg_ttl_whatbuy.JPG);
  background-position: top 50% center;
}

.cross-img-group-1 {
  background-image: url(#{$cf_base_url}/what_buy/img_whatbuy_01.png);
}

.coffee-in-the-world-map-group {
  padding: 5vh 0vw 5vh 0vw;
  margin: auto;
}
.coffee-in-the-world-map {
  width: 42vw;
  height: 21vw;
  background-image: url(#{$cf_base_url}/what_buy/world_map.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.card-body {
  background-color: $bg_white;
  min-height: 70vh;
  color: $text_dark_green;
}

.radar-chart {
  height: 12vw;
  width: 12vw;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}
.radar-chart-columbia {
  background-image: url(#{$cf_base_url}/what_buy/chart_columbia.png);
}
.radar-chart-ethiopia {
  background-image: url(#{$cf_base_url}/what_buy/chart_ethiopia.png);
}
.radar-chart-guatemala {
  background-image: url(#{$cf_base_url}/what_buy/chart_guatemala.png);
}
.radar-chart-indonesia {
  background-image: url(#{$cf_base_url}/what_buy/chart_indonesia.png);
}
.radar-chart-kenya {
  background-image: url(#{$cf_base_url}/what_buy/chart_kenya.png);
}

.td-green {
  background-color: $bg_dark_green;
}
.td-green-opacity {
  background-color: rgba(96, 105, 49, 0.1);
}
.coffee-card-group {
  padding: 0;
  max-width: 60vw !important;
  width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-content {
  position: relative;
  min-width: 18vw;
  width: 18vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.country-flag {
  position: absolute;
  top: -4vh;
  left: 3vw;
  height: 4.5vw;
  width: 4vw;
  z-index: 100;
  background-size: cover;
  background-repeat: no-repeat;
}
.country-flag-columbia {
  background-image: url(#{$cf_base_url}/what_buy/ic_columbia.png);
}
.country-flag-guatemala {
  background-image: url(#{$cf_base_url}/what_buy/ic_guatemala.png);
}
.country-flag-kenya {
  background-image: url(#{$cf_base_url}/what_buy/ic_kenya.png);
}
.country-flag-ethiopia {
  background-image: url(#{$cf_base_url}/what_buy/ic_ethiopia.png);
}
.country-flag-indonesia {
  background-image: url(#{$cf_base_url}/what_buy/ic_indonesia.png);
}
.card-title {
  padding: 0vh 1vw 0vw 1vw;
}
.price-headline-ja {
  text-align: center;
  padding: 2vh 0vw 0vw 0vw;
}
.price-notification {
  padding: 0vh 0vw 2vw 0vw;
}
.ic-bean {
  position: absolute;
  top: -4vh;
  left: 3vw;
  height: 4.8vw;
  width: 5vw;
  z-index: 100;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(#{$cf_base_url}/what_buy/ic_bean.png);
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .coffee-in-the-world-map-group {
    padding: 5vh 0vw 5vh 0vw;
    margin: auto;
  }
  .coffee-in-the-world-map {
    width: 90vw;
    height: 45vw;
  }

  .coffee-card-group {
    max-width: 100vw !important;
    width: 100vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .card-content {
    position: relative;
    min-width: 90vw;
    width: 90vw;
    padding-top: 0vh;
    padding-bottom: 0vh;
  }
  .card-body {
    min-height: 50vh !important;
  }
  .country-flag {
    position: absolute;
    top: -3vh;
    left: 5vw;
    height: 10vw;
    width: 10vw;
    z-index: 100;
  }
  .radar-chart {
    height: 30vw;
    width: 30vw;
  }
  .price-value {
    font-size: 10vw;
  }
  .price-unit {
    font-size: 1.2vw;
  }
  table {
    margin: auto;
  }
  .ic-bean {
    top: -2vh;
    left: 3vw;
    height: 10vw;
    width: 10vw;
    background-size: contain;
  }
  .price-notification-title {
    font-size: 4vw;
  }
  .price-message-group {
    display: flex;
    flex-direction: row;
    padding: 0vh 0vw 0vh 0vw;
  }
}
</style>
