import { render, staticRenderFns } from "./Use.vue?vue&type=template&id=56ab5fbf&scoped=true&"
import script from "./Use.vue?vue&type=script&lang=js&"
export * from "./Use.vue?vue&type=script&lang=js&"
import style0 from "./Use.vue?vue&type=style&index=0&id=56ab5fbf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ab5fbf",
  null
  
)

export default component.exports