import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contactInfo: {
      organization: { textValue: '', isValid: true },
      name: { textValue: '', isValid: true },
      name_rubi: { textValue: '', isValid: true },
      email: { textValue: '', isValid: true },
      comment: { textValue: '', isValid: true }
    }
  },
  mutations: {
    updateContactInfo: function (state, contactInfo) {
      state.contactInfo = contactInfo
    }
  },
  actions: {

  }
})
