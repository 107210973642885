<script>
import ApiClient from './ApiClient'

export default {
  name: 'RegistrationService',
  sendContactInfo: function (params, callback, errorHandler) {
    let targetPath = `/api/notification`
    ApiClient.create(targetPath, params, (response) => {
      callback(response)
    }, (error) => {
      errorHandler(error)
    })
  }
}
</script>

<style scoped>

</style>
