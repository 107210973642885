<template>
  <div class="container-fluid p-0">
    <div id="welcome" class="home-main bg-snow">
      <!-- WELCOME -->
      <div class="main-message-group">
        <div class="main-sub-message text-white">
          新橋から歩いてたった3分のマイ工房
        </div>
        <div class="main-message text-white">
          コーヒー焙煎で
          <br />
          毎日をほんの少し豊かに
        </div>
        <div class="btn-home-group">
          <a
            class="btn-base btn-beginner btn-beginner-lg"
            v-bind:href="reserveSeminarUrl"
            target="_blank"
          >
            <!--
            <div class="btn-beginner-img"></div>
            今すぐ焙煎体験
            -->
            リピート焙煎はこちら
          </a>
        </div>
      </div>
      <div class="scroll-group">
        <router-link to="/#concept">
          <div class="scroll-text">SCROLL</div>
        </router-link>
      </div>
    </div>
    <!-- NEWS -->
    <div id="news" class="container">
      <div class="row">
        <div class="col d-flex flex-column text-center py-5">
          <h3 class="my-0">NEWS</h3>
          <p class="p-strong">お知らせ</p>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2"/>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0"></p>
            <div class="news-content-message"></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column px-3">
            <p class="p-strong my-0"></p>
            <div class="news-content-message"></div>
          </div>
        </div>
        <div class="col-sm-0 col-md-0 col-lg-2"/>
      </div>
      -->
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2"/>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column px-3">
            <p class="p-strong my-0">2022.5.22</p>
            <div class="news-content-message" >
              2022年6月1日 より価格を改正します。
              <br/>
              <br/>
              <a href="/price" style="color: dodgerblue;">料金ページ</a> と <a href="/buy" style="color: dodgerblue;">生豆ページ</a> をご覧ください。
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column px-3">
            <p class="p-strong my-0">2021.11.01</p>
            <div class="news-content-message">
              営業再開しました。
              <br/>
              焙煎体験は引き続き１組までとさせていただいております。
            </div>
          </div>
        </div>
        <div class="col-sm-0 col-md-0 col-lg-2"/>
      </div>
      <!--
      <div class="row pt-3">
        <div class="col-sm-0 col-md-0 col-lg-2"/>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column px-3">
            <p class="p-strong my-0">2021.08.29</p>
            <div class="news-content-message">
              緊急事態宣言の延長、および、コロナの急拡大に伴い、
              <br/>
              当面の間、焙煎体験・リピート焙煎をお休みします。
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column px-3">
            <p class="p-strong my-0">2021.07.01</p>
            <div class="news-content-message">
              当面の間、焙煎体験をお休みしてリピート焙煎のみとします。
            </div>
          </div>
        </div>
        <div class="col-sm-0 col-md-0 col-lg-2"/>
      </div>
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2" />
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.06.14</p>
            <div class="news-content-message">
              <a
                href="/news/2020-06-14-about-point"
                target="_black"
                style="text-decoration: underline; color: deepskyblue"
                >生豆のポイント進呈を始めます！</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.05.31</p>
            <div class="news-content-message">
              <a
                href="https://coffeekoubou.tumblr.com/post/619168350877237248/"
                target="_black"
                style="text-decoration: underline; color: deepskyblue"
                >2020年6月の2週目から営業再開します！</a
              >
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2" />
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.04.04</p>
            <div class="news-content-message">
              <a
                href="https://coffeekoubou.tumblr.com/post/614423257583648768/counterplan-for-coronavirus"
                target="_black"
                >コロナ拡散防止のため営業自粛します。</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.03.15</p>
            <div class="news-content-message">
              <a
                href="https://coffeekoubou.tumblr.com/post/612155442276270081/discount"
                target="_black"
                style="text-decoration: underline; color: deepskyblue"
              >ご招待割引のお知らせ</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2" />
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.01.14</p>
            <div class="news-content-message">
              2020.1.17 - 2020.1.19 は研修のため臨時休業いたします。
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2019.12.24</p>
            <div class="news-content-message">
              <a
                href="https://coffeekoubou.tumblr.com/post/189841286064"
                target="_black"
                style="text-decoration: underline; color: deepskyblue"
              >2020年1月7日より価格改定いたします。</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2" />
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2019.11.05</p>
            <div class="news-content-message">
              <a
                href="https://coffeekoubou.tumblr.com"
                target="_black"
                style="text-decoration: underline; color: deepskyblue"
              >ブログを開設しました！</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2019.08.17</p>
            <div class="news-content-message">
              ホームページをリニューアルしました！
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="concept" class="container-fluid px-0 bg-snow py-5">
      <!-- Concept -->
      <div class="common-title-group">
        <h4>
          手ぶらで、好きな時に自由に
          <br />
          コーヒー焙煎を楽しむ場
        </h4>
        <div class="container">
          <p class="p-message">
            コーヒーが好きなあなたへ。
            <br />
            「一度でいいから、焙煎をしてみたい」と思ったことありませんか？
            <br />
            <br />
            でも、本格的に教わりに行くのもハードルが高いし
            <br />
            自分で機材や環境を整えるのはもっと大変…。
            <br />
            <br />
            もっと気軽に、自由に、誰にでも。
            <br />
            コーヒー焙煎を楽しんでほしい。
            <br />
            “ぼくらの焙煎工房”はそんな想いからできました。
            <br />
            <br />
            思い立ったら、いつでも手ぶらで東京の新橋までどうぞ。
            <br />
            コーヒー楽しみ方をもっと知ってみると
            <br />
            毎日が少し豊かになるかもしれません。
          </p>
        </div>
      </div>
    </div>
    <!-- What We Do -->
    <div id="what-we-do" class="container-fluid">
      <div class="row">
        <div class="col d-flex flex-column text-center py-5">
          <h3 class="my-0">WHAT WE DO</h3>
          <p class="p-strong">工房でできること</p>
        </div>
      </div>
      <div class="card-group d-flex justify-content-center">
        <div
          class="arrow arrow-left carousel-left-arrow"
          v-on:click="activateCarousel"
        ></div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-1': isCarouselActive_1 }"
        >
          <div class="card-img card-img-1">
            <div class="card-title-group">
              <div class="card-sub-title">コーヒー焙煎を</div>
              <div class="card-title">知る</div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-message">
              本格的なコーヒー焙煎体験ができるワークショップを開催。初めての方も工房スタッフが丁寧にお教えします。焙煎の世界をぜひ一度体感しに来てください。
            </div>
            <p class="text-red text-center">ご予約は3日前までです。</p>
            <router-link to="/price">
              <div class="btn-group d-flex justify-content-center">
                <div class="btn-base btn-basic text-white">詳しく見る</div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-2': isCarouselActive_2 }"
        >
          <div class="card-img card-img-2">
            <div class="card-title-group">
              <div class="card-sub-title">コーヒー焙煎機を</div>
              <div class="card-title">使える</div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-message">
              3種類ある焙煎機をお好きな時間で自由にご利用いただけます（事前予約制）。カフェ開業やロースターを目指している方など、様々な焙煎機を試してみたい方へおすすめです。
            </div>
            <router-link to="/use">
              <div class="btn-group d-flex justify-content-center py-2">
                <div class="btn-base btn-basic text-white">詳しく見る</div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-3': isCarouselActive_3 }"
        >
          <div class="card-img card-img-3">
            <div class="card-title-group">
              <div class="card-sub-title">コーヒー生豆を</div>
              <div class="card-title">買える</div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-message">
              手ぶらで焙煎機を利用いただけるよう、３大産地を中心に生豆も提供しています。もちろん、こだわりの生豆をお持ち込みいただくことも可能です。
            </div>
            <router-link to="/buy">
              <div class="btn-group d-flex justify-content-center py-2">
                <div class="btn-base btn-basic text-white">詳しく見る</div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="arrow arrow-right carousel-right-arrow"
          v-on:click="activateCarousel"
        ></div>
      </div>
    </div>
    <div id="price" class="common-main bg-snow">
      <!-- Price -->
      <div class="common-title-group">
        <div class="common-title">PRICE</div>
        <div class="common-subtitle-bold">料金案内</div>
      </div>
      <div class="card-group">
        <div
          class="arrow arrow-left carousel-left-arrow"
          v-on:click="activatePriceCarousel"
        ></div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-4': isCarouselActive_4 }"
        >
          <div class="price-box-body">
            <div class="balloon-comment text-white">
              一度焙煎をやってみたい方へ
            </div>
            <div class="price-message">
              コーヒー焙煎体験会
              <br />
              焙煎ワークショップ
            </div>
            <div class="price-box-info">
              <div class="price-value-small text-yellow">
                6,000<span class="price-unit">円/2時間（税込）</span>
              </div>
            </div>
            <router-link to="/price">
              <div
                class="btn-base btn-basic btn-margin-2 text-normal-ja text-white"
              >
                詳しく見る
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="card-content"
          v-bind:class="{ 'carousel-active-5': isCarouselActive_5 }"
        >
          <div class="price-box-body">
            <div class="balloon-bg-green text-normal-ja text-white">
              焙煎を繰り返ししたい方へ
            </div>
            <div class="text-headline-ja price-message">
              リピート焙煎
            </div>
            <div class="price-box-info">
              <div class="price-value-small text-green">
                3,000<span class="price-unit">円/1時間（税込）</span>
              </div>
              <div class="price-value-small text-green">
                5,000<span class="price-unit">円/2時間（税込）</span>
              </div>
            </div>
            <div class="text-headline-ja price-message">
              焙煎スペース貸切
            </div>
            <div class="price-box-info">
              <div class="price-value-small text-green">
                8,800<span class="price-unit">円 <br/>/1回・4時間〜（税込）</span>
              </div>
            </div>
            <router-link to="/price">
              <div
                class="btn-base btn-basic btn-margin-2 text-normal-ja text-white"
              >
                詳しく見る
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="arrow arrow-right carousel-right-arrow"
          v-on:click="activatePriceCarousel"
        ></div>
      </div>
    </div>
    <div id="access" class="common-main">
      <!-- Access -->
      <div class="common-title-group">
        <div class="common-title">ACCESS</div>
        <div class="common-subtitle-bold">工房のある場所</div>
        <div class="common-middle-title">
          新橋駅すぐだから、いつでも気軽に焙煎。
        </div>
      </div>
      <div class="card-group wrapper-column with-center">
        <div class="concept-message">
          〒105-0004 東京都港区新橋1-16-9 亀田ビル2F
          <br />
          JR「新橋駅」西口から徒歩2分
          <br />
          東京メトロ銀座線・都営浅草線「新橋駅」7番出口から 徒歩2分
        </div>
        <div class="company-info-col">
          <div id="map" class="company-info-map">
            <google-map
              :center="center"
              :zoom="zoom"
              :options="options"
              style="width: 100%; height: 100%; saturation: -100"
            >
              <google-marker
                v-for="m in markers"
                v-bind:key="m.id"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              ></google-marker>
            </google-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
  name: 'home',
  components: {
    'google-map': VueGoogleMaps.Map,
    'google-marker': VueGoogleMaps.Marker
  },
  head: {
    meta: [
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge;chrome=1' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
      {
        name: 'description',
        content: '珈琲焙煎を初心者〜中級者の方が気軽に行える場所を提供します。'
      },
      { property: 'og:title', content: 'ぼくらの焙煎工房 |  Coffee Koubou' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:image',
        content:
          'https://d2tndqfbw8m6qg.cloudfront.net/top/CoffeeKoubou-Top.JPG'
      },
      { property: 'og:image:alt', content: 'ぼくらの焙煎工房' },
      {
        property: 'twitter:title',
        content: 'ぼくらの焙煎工房 |  Coffee Koubou'
      },
      { property: 'twitter:card', content: 'summary_large_image' },
      {
        property: 'google-site-verification',
        content: '-8wni9b_47izGrz7CbVOfq4Nlk47RT7KkEwEVGT56Mo'
      }
    ]
  },
  data () {
    return {
      reserveSeminarUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar`,
      zoom: 15,
      center: {
        lat: 35.668257,
        lng: 139.756693
      },
      markers: [
        {
          position: {
            lat: 35.668257,
            lng: 139.756693
          }
        }
      ],
      options: {
        disableDefaultUI: true,
        scrollwheel: false,
        styles: [
          {
            stylers: [{ saturation: -100 }]
          }
        ]
      },
      isCarouselActive_1: true,
      isCarouselActive_2: false,
      isCarouselActive_3: false,
      isCarouselActive_4: true,
      isCarouselActive_5: false
    }
  },
  methods: {
    activateCarousel: function () {
      if (this.isCarouselActive_1) {
        this.isCarouselActive_1 = false
        this.isCarouselActive_2 = true
        this.isCarouselActive_3 = false
      } else if (this.isCarouselActive_2) {
        this.isCarouselActive_1 = false
        this.isCarouselActive_2 = false
        this.isCarouselActive_3 = true
      } else if (this.isCarouselActive_3) {
        this.isCarouselActive_1 = true
        this.isCarouselActive_2 = false
        this.isCarouselActive_3 = false
      } else {
        this.isCarouselActive_1 = true
        this.isCarouselActive_2 = false
        this.isCarouselActive_3 = false
      }
    },
    activatePriceCarousel: function () {
      if (this.isCarouselActive_4) {
        this.isCarouselActive_4 = false
        this.isCarouselActive_5 = true
      } else if (this.isCarouselActive_5) {
        this.isCarouselActive_4 = true
        this.isCarouselActive_5 = false
      } else {
        this.isCarouselActive_4 = true
        this.isCarouselActive_5 = false
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/sass/global";

.home-main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(#{$cf_base_url}/top/ttl_top.jpeg);
  background-position: center center;
  z-index: -1;
}
.home-main::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.main-message-group {
  z-index: 100;
  width: 40vw;
  text-align: center;
  padding: 10vh 0vw 0vh 0vw;
  margin: auto;
  max-height: 20vh;
  min-height: 61vh;
}
.main-sub-message {
  font-size: 1.3vw;
  padding: 0vh 0vw 3vw 0vw;
}
.main-message {
  font-size: 2vw;
  font-weight: bold;
  padding: 0vh 0vw 0vw 0vw;
  line-height: 2;
}

.btn-home-group {
  padding: 5vh 0vw 0vh 0vw;
}
.scroll-group {
  z-index: 100;
  display: flex;
  min-height: 20vh;
  flex-direction: column;
  cursor: pointer;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
}
.scroll-text {
  padding: 0vh 0vw 1vh 0vw;
  color: $text_white;
  font-size: 0.7vw;
  position: relative;
}
.scroll-text::before {
  content: "";
  position: absolute;
  top: 3vh;
  left: 0;
  right: 0;
  width: 1px;
  height: 10vh;
  margin: auto;
  background-color: $bg_white;
}

.concept-message {
  line-height: 2 !important;
}

.news-group {
  display: flex;
  flex-direction: row;
  padding: 5vh 30vw 5vh 30vw;
  width: 40vw;
}
.news-content {
  display: flex;
  flex-direction: column;
  text-align: left !important;
  width: 20vw;
}
.news-content-left {
  margin-right: auto;
}
.news-content-right {
  margin-left: auto;
}
.news-content-date {
  font-size: 0.8vw;
  font-weight: bold;
}
.news-content-message {
  padding: 1vh 0vw 0vh 0vw;
}

.card-title {
  position: relative;
}
.card-sub-title {
  position: relative;
}
.card-img-1 {
  position: relative;
  background-image: url(#{$cf_base_url}/top/img_top_what01.jpg);
  background-size: cover;
}
.card-img-1::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.card-img-2 {
  position: relative;
  background-image: url(#{$cf_base_url}/top/img_top_what02.jpg);
  background-size: cover;
}
.card-img-2::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.card-img-3 {
  position: relative;
  background-image: url(#{$cf_base_url}/top/img_top_what03.jpg);
  background-size: cover;
}
.card-img-3::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.price-box-group {
  display: flex;
  flex-direction: row;
}
.price-box-body {
  padding: 8vh 2vw 8vh 2vw;
  background-color: $bg_white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balloon-comment {
  position: relative;
  display: inline-block;
  padding: 1vh 0vw 1vh 0vw;
  min-width: 12vw;
  background: $text_yellow;
  font-size: 0.75vw !important;
  text-align: center;
}
.balloon-comment:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.5vw;
  border: 0.5vw solid transparent;
  border-top: 0.5vw solid $text_yellow;
}
.balloon-comment p {
  margin: 0;
  padding: 0;
}

.balloon-bg-green {
  position: relative;
  display: inline-block;
  padding: 1vh 0vw 1vh 0vw;
  min-width: 12vw;
  background: $bg_green;
  font-size: 0.75vw !important;
  text-align: center;
}
.balloon-bg-green:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.5vw;
  border: 0.5vw solid transparent;
  border-top: 0.5vw solid $bg_green;
}
.balloon-bg-green p {
  margin: 0;
  padding: 0;
}

.price-message {
  font-size: 1.1vw;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.1em;
  padding: 3vh 0vw 0vh 0vw;
  text-align: center;
}
.price-box-info {
  padding: 3vh 0vw 2vh 0vw;
}
.price {
  font-size: 2.7vw;
  font-weight: bold;
  letter-spacing: 0.08em;
}
.price-unit {
  font-size: 1vw !important;
}

.common-middle-title {
  font-size: 1.1vw;
  font-weight: bold;
  padding: 3vh 0vw 3vh 0vw;
}
.company-info-col {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 30vh;
  padding: 5vh 0vw 0vh 0vw;
}
.company-info-map {
  margin: auto;
  width: 50vw;
  height: 30vh;
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .home-main {
    height: 100vh;
  }
  .home-main::after {
    height: 100vh;
  }

  .main-message-group {
    width: 68vw;
    padding: 0vh 0vw 0vh 0vw;
    max-height: 20vh;
    min-height: 50vh;
    top: 0vh;
  }
  .btn-home-group {
    padding: 2vh 0vw 3vh 0vw;
  }
  .main-sub-message {
    font-size: 2vw !important;
    padding: 18vh 0vw 3vh 0vw;
  }
  .main-message {
    font-size: 6vw;
    font-weight: bold;
    padding: 0vh 0vw 0vw 0vw;
    line-height: 2;
  }

  .scroll-text {
    padding: 0vh 0vw 0vh 0vw;
    color: $text_white;
    font-size: 2vw;
    position: relative;
  }
  .scroll-text::before {
    content: "";
    position: absolute;
    top: 2vh;
    left: 0;
    right: 0;
    width: 1px;
    height: 10vh;
    margin: auto;
    background-color: $bg_white;
  }

  /* NEWS */
  .news-group {
    display: flex;
    flex-direction: column;
    padding: 0vh 0vw 0vh 0vw !important;
    text-align: left;
    width: 90vw;
  }
  .news-content {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    padding: 1vh 0vw 1vh 0vw !important;
    width: 90vw;
  }
  .news-content-left {
    margin-right: auto;
  }
  .news-content-right {
    margin-left: auto;
  }
  .news-content-date {
    font-size: 2vw;
    font-weight: bold;
  }
  .news-content-message {
    padding: 1vh 0vw 0vh 0vw;
  }

  /* CONCEPT_MOBILE */
  .concept-message {
    padding: 0vh 10vw 0vh 10vw !important;
  }

  /* PRICE_MOBILE */
  .price-box-body {
    padding: 5vh 2.5vw 5vh 2.5vw;
    background-color: $bg_white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  $balloon_width: 70vw;
  .balloon-comment {
    padding: 1vh 0vw 1vh 0vw;
    min-width: $balloon_width;
    font-size: 2vw !important;
  }
  $edge_length: 3vw;
  .balloon-comment:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -$edge_length;
    border: $edge_length solid transparent;
    border-top: $edge_length solid $text_yellow;
  }
  .balloon-comment p {
    margin: 0;
    padding: 0;
  }

  .balloon-bg-green {
    padding: 1vh 0vw 1vh 0vw;
    min-width: $balloon_width;
    font-size: 2vw !important;
  }
  .balloon-bg-green:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -$edge_length;
    border: $edge_length solid transparent;
    border-top: $edge_length solid $bg_green;
  }
  .balloon-bg-green p {
    margin: 0;
    padding: 0;
  }

  .price-message {
    font-size: 5vw;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 0.1em;
    padding: 3vh 0vw 0vh 0vw;
    text-align: center;
  }
  .price-box-info {
    padding: 3vh 0vw 2vh 0vw;
  }
  .price {
    font-size: 12vw;
    font-weight: bold;
    letter-spacing: 0.08em;
  }
  .price-unit {
    font-size: 4vw !important;
  }

  .common-middle-title {
    font-size: 1.8vw;
    font-weight: bold;
    padding: 3vh 0vw 3vh 0vw;
  }
  /* ACCESS_MOBILE */
  $company_map_width: 80vw;
  .company-info-col {
    width: $company_map_width;
  }
  .company-info-map {
    width: $company_map_width;
  }
}
</style>
