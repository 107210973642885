<template>
  <div class="privacy-policy">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">VOICE</div>
        <div class="content-header-sub-title">ご利用者の声</div>
      </div>
    </div>

     <div class="row">
        <div class="col d-flex flex-column text-center py-5">
          <h3 class="my-0">VOICE</h3>
          <p class="p-strong">ご利用者の声</p>
        </div>
      </div>

        <div class="row">
        <div class="col-sm-0 col-md-0 col-lg-2"/>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.08</p>
            <div class="news-content-message"><a href="https://mongome-kitchen.tokyo/coffee-koubou-2/" target="_black" style="text-decoration: underline; color: deepskyblue;">隠れコーヒーの街「新橋」で本格焙煎を体験しよう｜ぼくらの焙煎工房【後編】</a></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex flex-column p-3">
            <p class="p-strong my-0">2020.08</p>
            <div class="news-content-message"><a href="https://mongome-kitchen.tokyo/coffee-koubou-1/" target="_black" style="text-decoration: underline; color: deepskyblue;">「隠れコーヒーの街「新橋」で本格焙煎を体験しよう｜ぼくらの焙煎工房【前編】」</a></div>
          </div>
        </div>
        <div class="col-sm-0 col-md-0 col-lg-2"/>
      </div>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped lang="scss">
  @import '../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/privacy/bg_ttl_privacy.JPG);
    background-position: top 58% center;
  }

</style>
