<template>
  <div class="contact">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">CONTACT</div>
        <div class="content-header-sub-title">お問い合わせ</div>
      </div>
    </div>
    <div class="container-fluid py-5">
      <div class="row d-flex flex-column align-items-center py-3">
        <div class="col col-xs-10 col-md-4 d-flex flex-column text-center">
          <h5>お問い合わせ内容を<br class="is-mobile"/>ご確認ください</h5>
          <p>
            お問い合わせ内容はこちらでよろしいでしょうか？
            <br class="is-pc"/>
            よろしければ「送信する」ボタンを押して下さい。
          </p>
        </div>
      </div>
      <div class="row d-flex flex-column align-items-center py-3">
        <!-- Organization Name -->
        <div class="col-sm-10 col-md-5 py-1">
          <div class="cfm-elem-label headline-text">貴社名・店舗名</div>
          <div class="cfm-elem-text"> {{ form.organization.textValue }}</div>
        </div>
        <!-- Name -->
        <div class="col-sm-10 col-md-5 py-1">
          <div class="cfm-elem-label headline-text">お名前</div>
          <div class="cfm-elem-text">{{ form.name.textValue }}</div>
        </div>
        <!-- Name Rubi -->
        <div class="col-sm-10 col-md-5 py-1">
          <div class="cfm-elem-label headline-text">お名前（フリガナ）</div>
          <div class="cfm-elem-text">{{ form.name_rubi.textValue }}</div>
        </div>
        <!-- EMail -->
        <div class="col-sm-10 col-md-5 py-1">
          <div class="cfm-elem-label headline-text">メールアドレス</div>
          <div class="cfm-elem-text">{{ form.email.textValue }}</div>
        </div>
        <!-- Comment -->
        <div class="col-sm-10 col-md-5 py-1">
          <div class="cfm-elem-label headline-text">お問い合わせ内容</div>
          <div class="cfm-elem-textarea">{{ form.comment.textValue }}</div>
        </div>
      </div>
      <div class="row d-flex flex-column align-items-center py-3">
        <div class="btn-group wrapper-row">
          <div class="btn-base btn-basic btn-margin-2" v-on:click="proceedSendMail">送信する</div>
          <!-- <div class="btn-base btn-gray btn-margin-2">訂正する</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationService from '../../components/RegistrationService'

export default {
  name: 'Confirm',
  data () {
    return {
      form: {
        organization: { textValue: '', isValid: true },
        name: { textValue: '', isValid: true },
        name_rubi: { textValue: '', isValid: true },
        email: { textValue: '', isValid: true },
        comment: { textValue: '', isValid: true }
      }
    }
  },
  methods: {
    proceedSendMail: function () {
      let params = {
        company: this.form.organization.textValue,
        name: this.form.name.textValue,
        name_kana: this.form.name_rubi.textValue,
        email: this.form.email.textValue,
        message: this.form.comment.textValue
      }
      RegistrationService.sendContactInfo(params, (response) => {
        console.log(response)
        this.$router.push('/contact/thank-you')
      }, (error) => {
        console.error(error)
      })
    }
  },
  created: function () {
    this.form = this.$store.state.contactInfo
    if (this.form.name.textValue === '') {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/sass/global';

  .is-pc {
    display: block;
  }
  .is-mobile {
    display: none;
  }
  .content-header {
    background-image: url(#{$cf_base_url}/contact/bg_ttl_contact.JPG);
    background-position: top 70% center;
  }

  .common-subtitle {
    text-align: center;
  }

  .cfm-group {
    display: flex;
    flex-direction: column;
    padding: 0vh 30vw 0vh 30vw;
    width: 40vw;
    margin: auto;
  }
  .cfm-elem {
    display: flex;
    flex-direction: row;
    padding: 3vh 0vw 3vh 0vw;
  }
  .cfm-elem-label {
    font-weight: 500;
    width: 50%;
  }
  .cfm-elem-text {
    width: 100%;
    left: 0%;
  }
  .cfm-elem-textarea {
    width: 100%;
    left: 0%;
  }

  /* MOBILE */
  @media screen and (max-width: 768px) {
    .is-pc {
      display: none;
    }
    .is-mobile {
      display: block;
    }
    .common-subtitle {
      width: 80vw;
      text-align: left;
    }
    .cfm-group {
      padding: 0vh 10vw 0vh 10vw;
      width: 80vw;
    }
    .cfm-elem {
      display: flex;
      flex-direction: column;
      padding: 3vh 0vw 3vh 0vw;
    }
    .cfm-elem-label {
      font-weight: 500;
      width: 50%;
      padding-bottom: 2vh;
    }
    .cfm-elem-text {
      width: 100%;
      left: 0%;
      text-indent: 1em;
      font-size: 2vw;
    }
    .cfm-elem-textarea {
      width: 100%;
      text-indent: 1em;
      font-size: 2vw;
    }
  }

</style>
