<template>
  <div class="footer">
    <div class="footer-booking">
      <div class="common-title-group">
        <div class="common-title">RESERVATION</div>
        <div class="common-subtitle-bold">各種予約</div>
        <div class="hidden-row"></div>
      </div>
      <div class="booking-footer-button-group">
        <a class="btn-base btn-beginner btn-margin-2" v-bind:href="reserveSeminarUrl" target="_blank">
          <div class="btn-beginner-img"></div>
          焙煎体験 予約
        </a>
        <!--
        <a class="btn-base btn-booking btn-margin-2" v-bind:href="reserveSeminarUrl" target="_blank">
          ワークショップ 予約
        </a>
        -->
        <a class="btn-base btn-booking btn-margin-2" v-bind:href="reserveRoasterUrl" target="_blank">
          焙煎機利用 予約
        </a>
      </div>
    </div>
    <div class="footer-company with-center">
      <div class="page-top-group with-center">
        <router-link to="/">
          <div class="page-top-text text-white">PAGE TOP</div>
        </router-link>
      </div>
      <div class="company-name">運営会社：ワイエスプランニング株式会社</div>
      <div class="company-info-group">
        <router-link to="/contact">
          <div class="footer-text">お問い合わせ</div>
        </router-link>
        <a class="footer-text">|</a>
        <router-link to="/privacy-policy">
          <div class="footer-text">プライバシーポリシー</div>
        </router-link>
      </div>
      <div class="copy-license">©Y.S.Planning. Co., Ltd.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonFooter',
  data () {
    return {
      reserveSeminarUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar`,
      reserveRoasterUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar?category=roaster`
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/global';

  .footer-booking {
    background-color: $bg_cream;
    padding: 5.5vh 0vw 5.5vh 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
  }
  .hidden-row {
    height: 5vh;
  }
  .booking-footer-button-group {
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  .footer-company {
    background-color: $bg_black;
    padding: 0vh 0vw 2.7vh 0vw;
    display: flex;
    flex-direction: column;
    color: $text_white;
    font-size: 0.7vw;
    line-height: 1.5;
  }
  .page-top-group {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 5vh 0vw 0vh 0vw;
  }
  .page-top-text {
    padding: 1vh 0vw 0vh 0vw;
    height: 4vh;
    position: relative;
  }
  .page-top-text::before {
    content: '';
    position: absolute;
    top: -5vh;
    left: 0;
    right: 0;
    width: 1px;
    height: 4.5vh;
    margin: auto;
    background-color: $bg_white;
  }
  .company-info-group {
    display: flex;
    flex-direction: row;
    padding: 0.5vh 0vw 0.5vh 0vw;
  }
  .footer-text {
    color: $text_white !important;
    margin: 0vh 0.2vw 0vh 0.2vw;
  }

  @media screen and (max-width: 768px) {
    .booking-footer-button-group {
      display: flex;
      flex-direction: column;
      margin: auto;
    }
    .footer-company {
      font-size: 2vw;
    }
  }
</style>
