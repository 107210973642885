<template>
  <div class="header">
    <div class="header-top-line">
      <div class="logo-group">
        <div class="logo-title">
          <router-link to="/" class="text-decoration-none">
            ぼくらの焙煎工房
          </router-link>
        </div>
      </div>
      <div class="menu-right-group">
        <div class="auth-group" v-on:click="goToReserve">
          <div class="auth-icon">
            <i class="fas fa-lock fa-2x text-yellow"></i>
          </div>
          <a class="auth-button" v-bind:href="reserveLoginUrl" >
            <div class="auth-button-text">ログイン</div>
            <div class="auth-button-text">会員登録</div>
          </a>
        </div>
        <div class="menu-trigger-group with-center">
          <a class="menu-trigger" v-bind:class="{'active': isMenuActive}" v-on:click="activateMenu">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </div>
    <transition name="fade">
    <div class="menu" v-if="isMenuActive">
      <div class="menu-main">
        <div class="booking-header-button-group">
          <a class="btn-base btn-beginner" v-bind:href="reserveSeminarUrl" target="_blank">
            <div class="btn-beginner-img"></div>
            焙煎体験 予約
          </a>
          <a class="btn-base btn-booking btn-margin-both-2" v-bind:href="reserveSeminarUrl" target="_blank">
            ワークショップ 予約
          </a>
          <a class="btn-base btn-booking" v-bind:href="reserveRoasterUrl" target="_blank">
            焙煎機利用 予約
          </a>
        </div>
        <div class="menu-element-group">
          <router-link to="/#news">
            <div class="menu-element text-white wrapper-row" v-on:click="activateMenu">
              NEWS<p class="menu-element-subtitle">お知らせ</p>
            </div>
          </router-link>
          <router-link to="/#what-we-do">
            <div class="menu-element text-white wrapper-row" v-on:click="activateMenu">
              WHAT WE DO<p class="menu-element-subtitle">工房でできること</p>
            </div>
          </router-link>

           <router-link to="/voice">
            <div class="menu-element text-white wrapper-row" v-on:click="activateMenu">
              VOICE<p class="menu-element-subtitle">ご利用者の声</p>
            </div>
          </router-link>

          <router-link to="/price">
            <div class="menu-element text-white wrapper-row" v-on:click="activateMenu">
              PRICE<p class="menu-element-subtitle">料金案内</p>
            </div>
          </router-link>
          <router-link to="/#access">
            <div class="menu-element text-white wrapper-row" v-on:click="activateMenu">
              ACCESS<p class="menu-element-subtitle">工房のある場所</p>
            </div>
          </router-link>
          <div class="menu-element-half-group wrapper-row">
            <router-link to="/contact">
              <div class="menu-element-half text-white wrapper-row" v-on:click="activateMenu">
                <p class="menu-element-subtitle-half">お問い合わせ</p>
              </div>
            </router-link>
            <router-link to="/privacy-policy">
              <div class="menu-element-half text-white wrapper-row" v-on:click="activateMenu">
                <p class="menu-element-subtitle-half">プライバシーポリシー</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CommonHeader',
  props: {},
  data () {
    return {
      reserveLoginUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/sign-in`,
      reserveSeminarUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar`,
      reserveRoasterUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar?category=roaster`,
      isMenuActive: false
    }
  },
  methods: {
    goToReserve: function () {
      window.location.replace(this.reserveLoginUrl)
    },
    activateMenu: function () {
      if (this.isMenuActive) {
        this.isMenuActive = false
      } else {
        this.isMenuActive = true
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/global';

  .header {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 500;
  }
  .header-top-line {
    z-index: 100;
    margin: 0;
    height: 9vh;
    width: 100vw;
    text-align: center;
    background-color: $bg_white;
    display: flex;
    flex-direction: row;
  }

  .logo-group {
    height: 4.3vh;
    padding: 3vh 0vw 2vh 5.2vw;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  .logo-title {
    font-size: 1.6vw;
    font-weight: bold;
    color: $text_black;
  }

  .menu-right-group {
    margin-left: auto;
    display: flex;
    flex-direction: row;
  }
  .auth-group {
    width: 15vw;
    text-align: center;
    background-color: $bg_black;
    padding: 2.0vh 0vw 2.0vh 0vw;
    display: flex;
    flex-direction: row;
  }
  .auth-icon {
    width: 40%;
    margin: auto;
  }
  .auth-button {
    width: 60%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .auth-button-text {
    font-size: 1vw !important;
    color: $text_white !important;
    padding-bottom: 0.5vh;
    line-height: 1.2 !important;
  }

  .menu-trigger-group {
    width: 13vw;
    display: flex;
    flex-direction: column;
    padding: 3vh 0vw 3vh 0vw;
  }

  /* ハンバーガーメニュー */
  .menu-trigger,
  .menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
  }
  .menu-trigger {
    position: relative;
    width: 3vw;
    height: 3vw;
  }
  .menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $bg_black;
    border-radius: 2px;
  }

  $menu-trigger-top: 1.3vh;
  .menu-trigger span:nth-of-type(1) {
    top: 0;
  }
  .menu-trigger span:nth-of-type(2) {
    top: $menu-trigger-top;
  }
  .menu-trigger span:nth-of-type(3) {
    bottom: 0;
  }
  .menu-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY($menu-trigger-top) rotate(-315deg);
    transform: translateY($menu-trigger-top) rotate(-315deg);
  }
  .menu-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }
  .menu-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(-$menu-trigger-top) rotate(315deg);
    transform: translateY(-$menu-trigger-top) rotate(315deg);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .menu {
    position: absolute;
    top: 9vh;
    z-index: 400;
    height: 91vh;
    width: 100vw;
    background-color: rgba(59, 47, 28, 0.8);
  }
  .menu-main {
    padding: 10vh 30vw 10vh 30vw;
  }
  .booking-header-button-group {
    padding: 8vh 0vw 5vh 0vw;
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  .menu-element-group {
    padding: 5vh 0vw 5vh 0vw;
  }
  .menu-element {
    width: 40vw;
    font-size: 1.5vw;
    line-height: 2;
    font-weight: bold;
    letter-spacing: 0.13vw;
    text-indent: 2vw;
    border-bottom: solid 1px $bg_white;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .menu-element-subtitle {
    font-size: 0.7vw;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-indent: 1vw;
    align-items: baseline;
    margin-bottom: 0.5em;
  }

  .menu-element-half-group {
    padding: 0vw 0vw 0vh 0vw;
    width: 38vw;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .menu-element-half {
    font-size: 0.7vw;
    font-weight: normal;
    line-height: 3.5;
    letter-spacing: 0.2em;
  }
  .menu-element-subtitle-half {
    text-indent: 2vw;
    width: 19.5vw;
    border-bottom: solid 1px $bg_white;
    margin-right: 1vw;
  }

  /* MOBILE */
  @media screen and (max-width: 768px) {
    .header-top-line {
      height: 8vh;
    }
    // TODO
    .logo-group {
      height: 6vh;
      padding: 3vh 0vw 2vh 5vw;
    }
    .logo-title {
      font-size: 4.3vw !important;
    }

    .auth-group {
      width: 30vw;
      padding: 1.0vh 0vw 0vh 0vw;
    }
    .auth-button-text {
      font-size: 2.2vw !important;
      color: $text_white !important;
      padding-bottom: 0.5vh;
      line-height: 2 !important;
    }
    .menu-trigger-group {
      width: 13vw;
      padding: 2.5vh 0vw 2.5vh 0vw;
    }
    .menu-trigger {
      position: relative;
      width: 6vw;
      height: 6vw;
    }

    /* HIDDEN_MENU */
    .menu {
      position: absolute;
      top: 8vh;
      z-index: 400;
      height: 92vh;
    }
    .menu-main {
      padding: 0;
      -webkit-justify-content: center !important;
      justify-content: center !important;
      margin: auto;
    }
    .booking-header-button-group {
      padding: 8vh 15vw 5vh 15vw;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
    .btn-margin-both-2 {
      margin: 0 !important;
    }
    .btn-base {
      margin: 2vh 0vw 2vh 0vw;
    }
    .menu-element-group {
      padding: 0vh 10vw 0vh 10vw;
      width: 80vw;
    }
    .menu-element {
      width: 80vw;
      font-size: 5.5vw;
      line-height: 2;
      font-weight: bold;
      letter-spacing: 0.13vw;
      text-indent: 2vw;
      border-bottom: solid 1px $bg_white;
    }
    .menu-element-subtitle {
      font-size: 2vw;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-indent: 3vw;
      align-items: baseline;
      margin-bottom: 0.5em;
    }

    .menu-element-half-group {
      padding: 0vw 0vw 0vh 0vw;
      width: 80vw;
    }
    .menu-element-half {
      width: 41vw;
      font-size: 2vw;
      font-weight: normal;
      line-height: 3.5;
      letter-spacing: 0.2em;
    }
    .menu-element-subtitle-half {
      text-indent: 2vw;
      width: 40vw;
      border-bottom: solid 1px $bg_white;
      margin-right: 2vw;
    }
  }
</style>
