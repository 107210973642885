<template>
  <div class="contact">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">CONTACT</div>
        <div class="content-header-sub-title">お問い合わせ</div>
      </div>
    </div>
    <div class="common-main">
      <div class="common-title-group">
        <div class="text-headline-ja common-title">
          お問い合わせありがとうございました
        </div>
        <div class="text-headline-ja common-subtitle">
          お問い合わせ内容を確認次第ご返答させていただきますので、
          <br/>
          今しばらくお待ちくださいますよう、よろしくお願い申し上げます。
        </div>
      </div>
      <div class="btn-group">
        <div class="btn-base btn-basic btn-margin-2" v-on:click="goToTop">トップページへ戻る</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
  methods: {
    goToTop: function () {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/sass/global';

  .content-header {
    background-image: url(#{$cf_base_url}/contact/bg_ttl_contact.JPG);
    background-position: top 70% center;
  }

  .common-subtitle {
    text-align: center;
  }

</style>
