<template>
  <div class="price">
    <div class="content-header">
      <div class="content-header-title-group">
        <div class="content-header-title">PRICE</div>
        <div class="content-header-sub-title">料金案内</div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row py-5 d-flex justify-content-center">
        <div class="card-content is-visible card-content-lg bg-snow">
          <div class="card-sub-title">
            各種ワークショップ・焙煎機利用・生豆のご購入ともに、事前に予約フォームよりご予約の上、体験日＆ご利用当日に工房にて
            <span style="font-weight: bold">現金でのお支払い</span
            >をお願いしております。 領収書ご希望の方は、当日お申し付けください。
          </div>
        </div>
      </div>
      <div class="common-title-group">
        <div class="common-title">
          コーヒー焙煎体験会＆
          <br />
          リピート焙煎
        </div>
      </div>
      <div class="row py-5 d-flex justify-content-center">
        <div class="card-content is-visible card-content-lg card-bordered">
          <div class="card-body card-body-with-image">
            <div class="card-img-group">
              <div class="card-img card-img-1"></div>
            </div>
            <div class="card-img-explain with-center">
              <div class="label-border"><p>初心者＆経験者</p></div>
              <div class="card-title">コーヒー焙煎体験会</div>
              <div class="price-message-group">
                <div class="price-value text-yellow">6,000</div>
                <div class="price-unit text-yellow">円/2時間（税込）</div>
              </div>
              <div class="card-sub-title">定員：6人 | 生豆：グアテマラ</div>
              <div class="btn-group">
                <a
                  class="btn-base btn-beginner"
                  v-bind:href="reserveSeminarUrl"
                  target="_blank"
                  >焙煎体験 予約</a
                >
              </div>
            </div>
          </div>
          <div class="card-body card-detail">
            <div class="card-sub-title card-sub-title-left">内容詳細</div>
            <div class="card-message-group">
              <div class="card-message">
                <p>1. コーヒー豆知識を知ろう</p>
                「コーヒーベルト」や代表的な品種についてお話します。
              </div>
              <div class="card-message">
                <p>2. 焙煎方法って？</p>
                ご家庭でも試せる方法から焙煎機まで、様々な焙煎方法をお話します。
              </div>
            </div>
            <div class="card-message-group">
              <div class="card-message">
                <p>3. 焙煎してみよう</p>
                焙煎機「ディスカバリー」で焙煎を体験してみましょう。
              </div>
              <div class="card-message">
                <p>4. 飲んでみよう</p>
                焙煎してみたコーヒーを試飲してみましょう。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-5 d-flex justify-content-center">
        <div class="card-content is-visible card-content-lg card-bordered">
          <div class="card-body card-body-with-image">
            <div class="card-img-group">
              <div class="card-img card-img-2"></div>
            </div>
            <div class="card-img-explain">
              <div class="label-border">
                <p class="">２回目以降ご参加の方</p>
              </div>
              <div class="card-title mb-0">リピート焙煎</div>
              <div class="price-message-group pb-0">
                <div class="price-value-small text-yellow">3,000</div>
                <div class="price-unit text-yellow">円/1時間（税込）</div>
              </div>
              <div class="price-message-group">
                <div class="price-value-small text-yellow">5,000</div>
                <div class="price-unit text-yellow">円/2時間（税込）</div>
              </div>
              <div class="card-title mb-0">焙煎スペース貸切</div>
              <div class="price-message-group pb-0">
                <div class="price-value-small text-yellow">8,800</div>
                <div class="price-unit text-yellow">円/1回・4時間〜（税込）</div>
              </div>
              <div class="btn-group">
                <a
                  class="btn-base btn-basic"
                  v-bind:href="reserveRoasterUrl"
                  target="_blank"
                  >リピート焙煎 予約</a
                >
              </div>
            </div>
          </div>
          <div class="card-body card-detail">
            <div class="card-sub-title card-sub-title-left">内容詳細</div>
            <div class="card-message-group">
              <div></div>
              <div class="card-message">
                <p>1. 直火、半熱風の違いを体験しよう</p>
                焙煎体験コースではディスカバリーのみの体験でしたがアポロ、ナナハンを利用して焙煎機による違いを体験してみてください。
              </div>
              <div class="card-message">
                <p>2. 自分で焙煎してみよう</p>
                リピート焙煎では基本的には付きっきりの説明などはありません。自分好みの焙煎を見つけてみてください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <hr className="my-2" />
    </div>
    <div class="common-main pb-0 pt-0">
      <div class="common-title-group">
        <div class="text-headline-ja common-title">コーヒー焙煎機</div>
      </div>
      <div class="card-group-bordered wrapper-column">
        <div class="roaster-price-group">
          <div class="card-content-sm">
            <div class="card-img-sm card-img-3"></div>
            <div class="card-body">
              <div class="card-title-sm">ディスカバリー</div>
              <router-link to="/use#carousel_1">
                <div class="card-sub-title with-link">特徴やスペックをみる</div>
              </router-link>
              <!--
              <div class="price-message-group">
                <div class="roaster-price-value">2,200</div>
                <div class="roaster-price-unit">円/時間（税込）</div>
              </div>
              -->
            </div>
          </div>
          <div class="card-content-sm">
            <div class="card-img-sm card-img-4"></div>
            <div class="card-body with-center">
              <div class="card-title-sm">アポロ</div>
              <router-link to="/use#carousel_2">
                <div class="card-sub-title with-link">特徴やスペックをみる</div>
              </router-link>
              <!--
              <div class="price-message-group">
                <div class="roaster-price-value">2,200</div>
                <div class="roaster-price-unit">円/時間（税込）</div>
              </div>
              -->
            </div>
          </div>
          <div class="card-content-sm">
            <div class="card-img-sm card-img-5"></div>
            <div class="card-body">
              <div class="card-title-sm">ナナハン</div>
              <router-link to="/use#carousel_3">
                <div class="card-sub-title with-link">特徴やスペックをみる</div>
              </router-link>
              <!--
              <div class="price-message-group">
                <div class="roaster-price-value">2,200</div>
                <div class="roaster-price-unit">円/時間（税込）</div>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="btn-group">
          <a
            class="btn-base btn-basic btn-base-large text-white"
            v-bind:href="reserveRoasterUrl"
            target="_blank"
            >焙煎機利用 予約</a
          >
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <hr className="my-4" />
    </div>
    <div class="container">
      <div class="row py-1">
        <div class="col d-flex justify-content-center">
          <h5>コーヒー生豆 購入料金</h5>
        </div>
      </div>
      <div class="row py-1">
        <div class="col d-flex justify-content-center">
          <router-link to="/buy">
            <div
              class="btn-base btn-basic btn-margin-2 btn-base-large text-normal-ja text-white"
            >
              取り扱い生豆 一覧
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <hr className="my-4" />
    </div>
    <div class="container py-5">
      <div class="row py-1">
        <div class="col d-flex justify-content-center">
          <h5>コーヒー関連備品料金</h5>
        </div>
      </div>
      <div class="wrapper-column">
        <div class="col d-flex justify-content-center">
          <table>
            <thead>
            <th></th>
            <th></th>
            </thead>
            <tbody>
            <tr>
              <td class="td-green">袋代（クラフト紙）</td>
              <td class="td-green-opacity">50円/1袋</td>
            </tr>
            <tr>
              <td class="td-green">袋代（プラスチック）</td>
              <td class="td-green-opacity">100円/1袋</td>
            </tr>
            <tr>
              <td class="td-green">袋代（手提げ）</td>
              <td class="td-green-opacity">50円/1袋</td>
            </tr>
            <tr>
              <td class="td-green">プリンター出力料</td>
              <td class="td-green-opacity">30円/1袋</td>
            </tr>
            <tr>
              <td class="td-green">ミネラルウォーター</td>
              <td class="td-green-opacity">100円/1本</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <div class="card-message">
            <p class="pt-5 text-center">
              ※カッピング用具を一式用意しております。
              <br/>
              ご希望の方は時間内で利用可能です。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'price',
  data () {
    return {
      reserveSeminarUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar`,
      reserveRoasterUrl: `${process.env.VUE_APP_RESERVE_ORIGIN}/calendar?category=roaster`
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/sass/global";

.content-header {
  background-image: url(#{$cf_base_url}/price/bg_ttl_price.JPG);
  background-position: top 90% center;
}

.card-sub-title {
  padding: 0vh 3vw 0vh 3vw;
  font-weight: normal !important;
  text-align: left;
}

.common-title-group {
  padding: 10vh 0vw 2vh 0vw;
}

.roaster-price-group {
  display: flex;
  flex-direction: row;
}
.card-title {
  padding: 2vh 0vw 0vh 0vw;
}
.card-body {
  background-color: $bg_white;
  min-height: 10vh;
}
.card-body-with-image {
  display: flex;
  flex-direction: row;
  padding: 0vh 1vw 0vh 1vw;
}
.card-img-group {
  padding: 0vh 0vw 0vh 0vw;
}
.card-img {
  width: 23vw;
  height: 32vh;
}
.card-img-1 {
  position: relative;
  background-image: url(#{$cf_base_url}/price/img_price01_01.jpg);
}
.card-img-1::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.card-img-2 {
  position: relative;
  background-image: url(#{$cf_base_url}/price/img_price01_02.jpg);
}
.card-img-2::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.card-img-explain {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.price-message-group {
  padding: 0vh 0vw 0vh 0vw;
}
.btn-group {
  padding: 1vh 0vw 0vh 0vw;
}
.card-detail {
  margin: 2vh 1vw 0vh 1vw;
  background-color: $bg_snow;
}
.card-sub-title-left {
  font-weight: bold !important;
  padding: 0vh 2vw 0vh 2vw !important;
}
.card-message-group {
  display: flex;
  flex-direction: row;
  padding: 1vh 0vw 1vh 0vw;
}
.card-message {
  width: 22vw;
  font-weight: normal;
  padding: 0vh 2vw 0vh 2vw;
}
.card-message p {
  margin: 0;
  font-weight: bold;
  padding-bottom: 1vh;
}

.card-img-3 {
  position: relative;
  background-image: url(#{$cf_base_url}/what_use/img_roaster_discovery.jpg);
}
.card-img-3::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) !important;
}
.card-img-4 {
  position: relative;
  background-image: url(#{$cf_base_url}/what_use/img_roaster_aporo.jpg);
}
.card-img-4::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) !important;
}
.card-img-5 {
  position: relative;
  background-image: url(#{$cf_base_url}/what_use/img_roaster_nanahan.jpg);
}
.card-img-5::before {
  content: "";
  position: absolute;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) !important;
}

.with-link {
  font-size: 0.7vw !important;
  letter-spacing: 0em;
  padding-bottom: 2vh;
  text-decoration: underline !important;
}
.price-value {
  font-size: 3vw;
}
.price-unit {
  font-size: 1vw !important;
}

.roaster-price-value {
  font-size: 2.5vw;
  letter-spacing: 0.01em;
  color: $text_green;
}
.roaster-price-unit {
  font-size: 1vw;
  letter-spacing: 0.01em;
  color: $text_green;
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .card-sub-title {
    font-size: $fz_sp_common !important;
  }
  .card-body-with-image {
    display: flex;
    flex-direction: column;
    padding: 0vh 0vw 0vh 0vw !important;
  }
  $card_content_width: 86vw;
  $card_img_width: $card_content_width;
  .card-img {
    width: $card_img_width;
    height: 32vh;
    margin: auto;
  }
  .card-img-explain {
    padding: 3vh 0vw 3vh 0vw !important;
  }
  .card-title {
    padding: 2vh 0vw 0vh 0vw;
    font-size: 4vw;
  }

  .price-value {
    font-size: 12vw;
    font-weight: bold;
  }
  .price-unit {
    font-size: 4vw !important;
  }

  .card-message-group {
    display: flex;
    flex-direction: column;
    padding: 1vh 0vw 1vh 0vw;
  }
  .card-message {
    padding: 0vh 4vw 2vh 4vw;
    min-width: 78vw;
    letter-spacing: 0.02em;
  }
  .roaster-price-group {
    display: flex;
    flex-direction: column;
  }
  .with-link {
    text-align: center !important;
  }
  .roaster-price-value {
    font-size: 6vw;
    letter-spacing: 0.01em;
    color: $text_green;
  }
  .roaster-price-unit {
    font-size: 2vw !important;
    letter-spacing: 0.01em;
    color: $text_green;
  }
}
</style>
